import cx from 'classnames';
import * as React from 'react';

type ContainerProps = JSX.IntrinsicElements['div'];

/**
 * Bootstrap container
 * @deprecated
 */
export const Container = (props: ContainerProps) => <div {...props} className={cx('container', props.className)} />;
