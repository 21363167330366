import cx from 'classnames';
import * as React from 'react';
import { useId } from '../../../utils/hooks';
import { FlosIcon } from '../../../core/icon/flos-icon';

type AttentionBannerProps = {
    /** variant of the banner */
    variant?: 'warning' | 'success' | 'error';
    /**
     * show the close Button for user to hide this attention banner
     */
    dismissible?: boolean;
    /**
     * show an icon before text
     */
    iconShape?: string;
    children: React.ReactNode;
    /**
     * on dismiss handler when close icon clicked
     */
    dismissHandler?: () => void;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 *
 * A full-width banner component
 */
const AttentionBanner = ({ variant, iconShape, dismissible, className, children, dismissHandler, ...props }: AttentionBannerProps) => {
    const [hidden, setHidden] = React.useState(false);
    const confirmedId = useId(props.id);

    return hidden ? null : (
        <article className={cx('AttentionBanner', className, variant && 'AttentionBanner--' + variant)} {...props}>
            <div className={'container'}>
                {iconShape && <FlosIcon shape={iconShape} size={32} />}
                {children}
                {dismissible && (
                    <a
                        onClick={(ev) => {
                            ev.preventDefault();
                            dismissHandler && dismissHandler();
                            setHidden(true);
                        }}
                        href={`#${confirmedId}-close`}
                        id={`${confirmedId}-close`}
                        className="AttentionBanner-close"
                        role="button"
                    >
                        <FlosIcon shape="close" size={32} />
                    </a>
                )}
            </div>
        </article>
    );
};

export type { AttentionBannerProps };
export { AttentionBanner };
export default AttentionBanner;
