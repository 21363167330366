import React from 'react';
import cx from 'classnames';
import { HandlerFunction } from '@storybook/addon-actions';

export interface IFlowMenuItemProps {
    /** Step title */
    title: React.ReactNode;
    /** shape of the icon */
    iconShape: string;
    /** if the box should be disabled - add it to next steps */
    isDisabled?: boolean;
    /** if the box should be dimmed - aka NOT active */
    isDimmed?: boolean;
    /** Method to call when clicking the FlowMenuItem */
    onClick?: HandlerFunction;
}

/**
 * `FlowMenuItem` is a component used in the FlowMenu
 */

export const FlowMenuItem = ({ title, iconShape, isDisabled, isDimmed, onClick = () => {} }: IFlowMenuItemProps) => (
    <a className={cx('Box', isDimmed && 'dimmed', isDisabled && 'disabled')} onClick={onClick}>
        <flos-icon shape={iconShape} />
        <p>
            <small>
                <strong>{title}</strong>
            </small>
        </p>
    </a>
);
