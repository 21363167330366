import { Logger } from '../types/types';

/**
 * Type definition copied from redux
 * -------
 * Start
 */

export interface IAnyAction<T = any> {
    type: T;
    // Allows any extra properties to be defined in an action.
    [extraProps: string]: any;
}

/* middleware */
type IDispatch<A = IAnyAction> = <T extends A>(action: T) => T;

interface MiddlewareAPI<D extends IDispatch = IDispatch, S = any> {
    dispatch: D;
    getState(): S;
}

/**
 * A middleware is a higher-order function that composes a dispatch function
 * to return a new dispatch function. It often turns async actions into
 * actions.
 *
 * Middleware is composable using function composition. It is useful for
 * logging actions, performing side effects like routing, or turning an
 * asynchronous API call into a series of synchronous actions.
 *
 * @template S The type of the state supported by this middleware.
 * @template D The type of Dispatch of the store where this middleware is
 *   installed.
 */
export type Middleware<S = any, D extends IDispatch = IDispatch> = (api: MiddlewareAPI<D, S>) => (next: IDispatch<IAnyAction>) => (action: any) => any;

/**
 * Type definition copied from redux
 * -------
 * End
 */

export type LogParams = {
    level: keyof Logger;
    msg: string;
};

export type Tracker = {
    pushEvent: (event: string, data: any) => void;
};

export type TrackParams = {
    event: string;
    data: any;
};

function defaultLogWhen(action: IAnyAction) {
    return !!(action && action.meta && action.meta.logLevel);
}

function defaultLogParamsSelector(action: IAnyAction): LogParams {
    const level = action && (action.meta.logLevel || 'error');
    const msg = action && (action.meta.logMessage || action.payload);
    return {
        level,
        msg: typeof msg === 'string' ? msg : JSON.stringify(msg),
    };
}

function defaultTrackWhen(action: IAnyAction) {
    return !!(action && action.meta && action.meta.track);
}

function defaultTrackParamsSelector(action: IAnyAction): TrackParams {
    return {
        data: action.meta.trackData,
        event: action.meta.track,
    };
}

export type MiddlewareOptions = {
    logger: Logger;
    logReducerError?: boolean;
    passThroughAction?: boolean;
    logWhen?: (action: IAnyAction) => boolean;
    logParamsSelector?: (action: IAnyAction) => LogParams;
    tracker: Tracker;
    trackWhen?: (action: IAnyAction) => boolean;
    trackParamsSelector?: (action: IAnyAction) => TrackParams;
};

/**
 * Create a redux middleware for logging and tracking.
 *
 * @deprecated Refactor your app to use tracking (https://github.devops.topdanmark.cloud/c9/c9-tracking) and logging (https://github.devops.topdanmark.cloud/c9/c9-js-logger) directly instead
 * This middleware implementation is out of date and lacks support for the latest arguments in the log client (log cannot be made with additional arguments like category, metadata and does not expose the new methods like logError and entities).
 * Also, this logging implementation implies that the consumer use Redux before app can take advantage of this in-built logging mechanism. Additionally, the logging mechanism is only available in the Redux "world" (e.x. it can only log things that are passed in Redux actions).
 *
 * See it in action [here](#middleware-demo).
 *
 * The `options` parameter is an object with the following properties:
 *
 * | Properties            | Required? | Description                                                                                                                                                                                                                                                                                                   |
 * | --------------------- | --------- | ------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------- |
 * | `logger`              | Required  | the logger object, which is the implementation of the `console` API. You can provide the logger object created by [`'c9-js-log-client'`](https://github.devops.topdanmark.cloud/c9/c9-js-log-client) or `window.console`.                                                                                            |
 * | `tracker`             | Required  | the tracker object, which is an object with `pushEvent` method. You can provide the object returned by `topContext.trackingHandlerFactory` or `{pushEvent: window.console.log}`                                                                                                                               |
 * | `logReducerError`     | Optional  | state if this middleware should log exception when running reducer. Default to `true`.                                                                                                                                                                                                                        |
 * | `passThroughAction`   | Optional  | state if the action should be pass through to reducer when it is logged or tracked. Default to `true`.                                                                                                                                                                                                        |
 * | `logWhen`             | Optional  | the predicate function to determine when the logger should be called. It takes action as parameter and returns boolean value (`true` to log, `false` to not log). Default to the following function: `const logWhen(action) => !!(action && action.meta && action.meta.logLevel);`.                     |
 * | `logParamsSelector`   | Optional  | the function to extract the log level and message from action when `logWhen` returns true                                                                                                                                                                                                                     |
 * | `trackWhen`           | Optional  | the predicate function to determine when the `pushEvent` method of tracker should be called. It takes action as parameter and returns boolean value (`true` to log, `false` to not log). Default to the following function: `const trackWhen(action) => !!(action && action.meta && action.meta.track);`. |
 * | `trackParamsSelector` | Optional  | the function to extract the tracking event and data from action when `trackWhen` returns true                                                                                                                                                                                                                 |
 *
 */
export function createLoggingAndTrackingMiddleware(options: MiddlewareOptions): Middleware {
    const {
        logger,
        tracker,
        logReducerError = true,
        passThroughAction = true,
        logWhen = defaultLogWhen,
        logParamsSelector = defaultLogParamsSelector,
        trackWhen = defaultTrackWhen,
        trackParamsSelector = defaultTrackParamsSelector,
    } = options;

    if (process.env.NODE_ENV !== 'production') {
        if (!logger || !logger.error) {
            console.error('invalid logger');
        }
    }
    if (process.env.NODE_ENV !== 'production') {
        if (!tracker || !tracker.pushEvent) {
            console.error('invalid tracker');
        }
    }
    return (store) => (next) => (action) => {
        try {
            if (logWhen(action)) {
                const { level, msg } = logParamsSelector(action);
                logger[level](msg);
            }

            if (trackWhen(action)) {
                const { event, data } = trackParamsSelector(action);
                tracker.pushEvent(event, data);
            }

            if (passThroughAction) {
                return next(action);
            }
        } catch (e) {
            const errorString = e instanceof Error ? e.toString() : JSON.stringify(e);
            console.error(errorString);

            if (logReducerError) {
                logger.error(`redux store error: ${errorString}`);
                logger.error(`store before error: ${JSON.stringify(store.getState())}`);
                logger.error(`action causing error: ${JSON.stringify(action)}`);
            }

            throw e;
        }
    };
}
