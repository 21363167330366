import cx from 'classnames';
import * as React from 'react';
import { callAll } from '../../../utils/fp';
import { FlosIcon } from '../../../core/icon/flos-icon';
import Button from '../../../core/button/button';

interface ModalDialogProps extends React.HTMLAttributes<HTMLDivElement> {
    /**
     * Sets the position of the modal
     */
    position?: undefined | 'center';
    /**
     * Specifies the background color of the modal.
     * Default is white.
     */
    background?: undefined | 'transparent';
    /**
     * Specifies if the mddal dialog should be in full screen.
     * Default is false.
     */
    isFullScreen?: boolean;
    /**
     * onClick handler if you want a close icon in upper right corner
     */
    closeIconHandler?: () => void;
}

/**
 * `ModalDialog` renders a dialog element. You should wrap it with
 * `ModalOverlay` other contents are de-emphasized.
 */
export const ModalDialog: React.FC<ModalDialogProps> = ({ className, onClick, children, position, background, isFullScreen, closeIconHandler, ...dialogProps }) => {
    return (
        <div
            className={cx(isFullScreen ? 'modal-dialog--fullscreen' : 'modal-dialog', position && `modal-dialog-${position}`, className)}
            role="document"
            onClick={callAll(onClick, (ev) => ev.stopPropagation())}
            {...dialogProps}
        >
            {closeIconHandler && isFullScreen && (
                <div className={'modal-dialog--fullscreen-header'}>
                    <div className={'topdk-modal-close'}>
                        <Button
                            variant="secondary"
                            role="button"
                            onClick={(event) => {
                                event.preventDefault();
                                closeIconHandler();
                            }}
                        >
                            <FlosIcon size={32} className={'is-indigo'} shape={'close'} />
                        </Button>
                    </div>
                </div>
            )}
            <div className={cx('topdk-modal__content', background && `topdk-modal__content-${background}`)}>
                {children}
                {closeIconHandler && !isFullScreen && (
                    <div className={'topdk-modal-close'}>
                        <Button
                            variant="secondary"
                            role="button"
                            onClick={(event) => {
                                event.preventDefault();
                                closeIconHandler();
                            }}
                        >
                            <FlosIcon size={64} className={'is-indigo'} shape={'close'} />
                        </Button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ModalDialog;
