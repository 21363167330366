export const createLogoutRef = (originalHost: string, sisterHost: string) => {
    if (originalHost.toLocaleLowerCase().includes('maegler')) {
        return `?ref=${encodeURIComponent(
            'https://' + sisterHost.replace(/min/, 'www') + '/erhverv/maegler-log-ind/'
        )}`;
    } else {
        return `${encodeURIComponent('https://' + sisterHost.replace(/min/, 'www'))}`;
    }
};

export default createLogoutRef;
