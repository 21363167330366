import cx from 'classnames';
import * as React from 'react';
import { FlosInputProps } from '../../input/flos-input';
import { ICheckboxGroupContext, CheckboxGroupContext } from './checkbox-group-context';
import { callAll } from '../../../../utils/fp';

export type CheckboxGroupProps = {
    label?: React.ReactNode;
    description?: React.ReactNode;
    /** error help text for the field that will only be displayed if status is error */
    errorText?: string;
    /**
     * states if the checkboxes should be arranged vertically
     * @default true
     */
    stacked?: boolean;
    values: Array<string | number | null>;
    isValid?: ICheckboxGroupContext['isValid'];
    required?: boolean;
    /**
     * callback with array of checked values (name prop)
     */
    onChangeValue?: (value: Array<string | number | null>) => void;
    children?: React.ReactNode;
    disabled?: boolean;
} & Omit<FlosInputProps, 'value'>;

export const CheckboxGroup = React.forwardRef<HTMLInputElement, CheckboxGroupProps>(
    ({ onChangeValue, label, description, errorText, values, stacked = true, children, isValid, required, disabled, ...rest }, ref) => {
        const contextValue = React.useMemo(
            () => ({
                values,
                isValid,
                required,
                disabled,
                errorText,
            }),
            [values, isValid, required, disabled, errorText]
        );

        const changeValues = (event: React.ChangeEvent<HTMLInputElement>) => {
            const value = event.target.name;

            if (onChangeValue) {
                if (values.indexOf(value) === -1 && event.target.checked) {
                    onChangeValue(values.concat(value));
                } else if (!event.target.checked) {
                    onChangeValue(values.filter((val) => val !== value));
                }
            }
        };

        return (
            <CheckboxGroupContext.Provider value={contextValue}>
                <fieldset>
                    {label && (
                        <legend>
                            <strong>
                                {label}
                                {required && '*'}
                            </strong>
                        </legend>
                    )}
                    <>
                        {description && <p>{description}</p>}
                        <div className={cx('flos-checkbox-group', stacked && 'flos-checkbox-group--stacked')} {...rest}>
                            {React.Children.map(children, (child, index) => {
                                if (React.isValidElement(child)) {
                                    return React.cloneElement(child as React.ReactElement, {
                                        ref: ref,
                                        onChange: callAll(child.props.onChange, changeValues),
                                        key: index,
                                        disabled: child.props.disabled || disabled,
                                        errorText: child.props.errorText || errorText,
                                        isValid: isValid,
                                        value: values.includes(child.props.name),
                                    });
                                } else {
                                    return;
                                }
                            })}
                        </div>
                    </>
                </fieldset>
            </CheckboxGroupContext.Provider>
        );
    }
);
CheckboxGroup.displayName = 'CheckboxGroup';
