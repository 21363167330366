import classnames from 'classnames';
import * as React from 'react';
import { FlosIcon } from '../../../icon/flos-icon';

interface ICoverageListItemProps extends React.DetailedHTMLProps<React.LiHTMLAttributes<HTMLLIElement>, HTMLLIElement> {
    icon?: 'included' | 'excluded';
}
export const CoverageListItem: React.FC<ICoverageListItemProps> = ({ className, icon, children, ...props }) => (
    <li className={classnames('flos-ResponsiveCoverage-listitem', className)} {...props}>
        {icon && <FlosIcon className={'tds-Coverage-icon tds-Coverage-icon--' + icon} shape={icon === 'included' ? 'check' : 'circle-x'} size={18} />}
        {children}
    </li>
);

export default CoverageListItem;
