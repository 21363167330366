import * as React from 'react';
import classnames from 'classnames';
import { FlosFormGroup } from './form-group/flos-form-group';
import { getId } from '../../utils';
import { callAll } from '../../utils/fp';
import { useState, useRef, useEffect, MutableRefObject } from 'react';

import type { FlosInputProps } from './input/flos-input';
import FlosIcon from '../icon/flos-icon';

type FieldProps<T> = T;

type FlosFieldRenderInputProps = {
    getInputProps: <T>(oriProps: FieldProps<T>) => FieldProps<T>;
};

type FlosFieldInternalProps = {
    /** the props to render your input */
    renderInput: (renderInputProps: FlosFieldRenderInputProps) => JSX.Element;
    /** the id of the input, important for accessibility */
    inputId?: string;
    hasDropdown?: boolean;
    readOnlyHandler?: () => void | null;
};

type FlosFieldProps = {
    /** label text */
    label?: string;
    /** className to be added to form group container */
    wrapperClassName?: string;
    /** icon to be added in the left side of the field */
    iconShape?: string;
    /** validation status of the field */
    isValid?: boolean;
    /** Error text for the field that will be displayed next to the label if the field is invalid */
    errorText?: string;
    required?: boolean;
    /** regex filtering out nonallowed chars */
    pattern?: string;
    /** set a max length on string */
    maxLength?: number;
    /** set a minimal length required */
    minLength?: number;
    id?: string;
    /** sets disabled state on the input field */
    disabled?: boolean;
    /** overwrite label and icons */
    custom?: boolean;
    /** show "Pen-icon" button at the end to control ReadOnly mode */
    editable?: boolean;
    /** callback having the current isValid state for the field  */
    onValidityChange?: (isValid: boolean | undefined) => void;
};

type FieldState = {
    isFocused?: boolean;
    isDirty?: boolean;
    isValid?: boolean;
    isReadOnly?: boolean;
};

type SecondaryIconProps = {
    isValid?: boolean;
    hasDropdown: boolean;
    disabled?: boolean;
    hasEditLink?: boolean;
};

const SecondaryIcon = ({ isValid, hasDropdown = false, disabled = false }: SecondaryIconProps) => {
    if (hasDropdown && (typeof isValid === 'undefined' || disabled === true)) {
        return <flos-icon size={32} class="flos-field-icon flos-field-icon--secondary" shape="arrow-down" />;
    }
    if (typeof isValid === 'undefined' || disabled) {
        return null;
    }
    return (
        <flos-icon
            size={32}
            class={classnames('flos-field-icon', 'flos-field-icon--secondary', 'has-fill', isValid ? 'is-green' : 'is-red')}
            shape={`${isValid ? 'positive' : 'negative'}`}
        />
    );
};

const LabelIcons = ({
    label,
    iconShape,
    required,
    errorText,
    disabled,
    inputId,
    isValid,
    hasDropdown = false,
    editable,
    readOnlyHandler,
}: FlosFieldProps & FlosFieldInternalProps) => {
    return (
        <>
            {label && (
                <label htmlFor={inputId} className="flos-label">
                    {label}
                    {isValid === false && !disabled && errorText && ` ${errorText}`}
                    {required && '*'}
                </label>
            )}
            {iconShape && <flos-icon class="flos-field-icon" shape={iconShape} size={32} />}
            {!editable && <SecondaryIcon disabled={disabled} hasDropdown={hasDropdown} isValid={isValid} />}
            {editable && (
                <button
                    className={'flos-field-edit-button'}
                    tabIndex={0}
                    onClick={() => {
                        readOnlyHandler && readOnlyHandler();
                    }}
                >
                    <FlosIcon className="is-interactive is-interactive--filled" shape={'edit'} size={32} />
                </button>
            )}
        </>
    );
};

/**
 * `Field` is the wrapper for form control.
 * It is just composition of `FormGroup` and `Label`.
 * If the composition here doesn't fit your use case, you could compose yourself.
 */
const FlosField = React.forwardRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement, FlosFieldProps & FlosFieldInternalProps>(
    ({ label, iconShape, isValid, required, renderInput, errorText, id, custom, hasDropdown = false, disabled, wrapperClassName, editable, onValidityChange }, ref) => {
        const [state, setState] = useState<FieldState>({
            isFocused: false,
            isDirty: false,
            isValid: undefined,
            isReadOnly: editable,
        });

        const inputId = id || getId();
        const formgroupRef = useRef<HTMLDivElement | null>(null);
        const inputRefInternal = ref || useRef<HTMLInputElement>(null);

        const handleReadOnlyState = () => {
            setState({ ...state, isReadOnly: !state.isReadOnly });
        };

        const handleFocus = () => {
            setState({ ...state, isFocused: true });
        };

        const handleBlur = (ev: React.FocusEvent<any>) => {
            setState({ ...state, isFocused: false, isValid: isValid, isReadOnly: editable ? true : false });

            if (ev.target.minLength && ev.target.value.length < ev.target.minLength) {
                setState({ ...state, isValid: false });
            }
        };

        const handleChange = () => {
            setState((pState) => ({ ...pState, isDirty: true, isValid: undefined }));
        };

        const handleKeyUp = () => {
            setState({ ...state, isDirty: true, isValid: undefined });
        };

        const handleKeyPress = (event: any) => {
            if (event.target && event.target.pattern) {
                if (typeof event.target.pattern === 'string') {
                    if (!regExTest(new RegExp(event.target.pattern), event.key)) event.preventDefault();
                } else if (!regExTest(event.target.pattern, event.key)) event.preventDefault();
            }
        };

        const getHelpTextId = () => `${inputId}-error`;

        const getAriaDescribedBy = (oriDescribedBy?: string) => {
            oriDescribedBy ? getHelpTextId() : undefined;
        };

        const getInputProps = <T extends FlosInputProps>(oriProps: T) => {
            return Object.assign({}, oriProps, {
                'aria-describedby': getAriaDescribedBy(oriProps['aria-describedby']),
                id: inputId,
                onBlur: callAll(oriProps.onBlur, handleBlur),
                onFocus: callAll(oriProps.onFocus, handleFocus),
                onChange: callAll(oriProps.onChange, handleChange),
                onKeyUp: callAll(oriProps.onKeyUp, handleKeyUp),
                onKeyPress: callAll(oriProps.onKeyPress, handleKeyPress),
                readOnly: typeof editable !== 'undefined' ? state.isReadOnly : oriProps.readOnly,
                ref: inputRefInternal,
            });
        };

        const regExTest = (regex: RegExp, key: string) => {
            return regex.test(key);
        };

        useEffect(() => {
            setState({ ...state, isValid: isValid });
        }, [isValid]);

        useEffect(() => {
            onValidityChange && onValidityChange(state.isValid);
        }, [state.isValid]);

        useEffect(() => {
            if (!state.isReadOnly && editable) {
                (inputRefInternal as MutableRefObject<HTMLInputElement>).current.focus();
            }
        }, [state.isReadOnly, editable]);

        return (
            <FlosFormGroup
                className={classnames('flos-field', iconShape && 'has-icon', editable && state.isReadOnly && 'has-edit-button', wrapperClassName && wrapperClassName)}
                isValid={disabled ? undefined : state.isValid}
                required={required}
                ref={formgroupRef}
            >
                {renderInput({ getInputProps: getInputProps as any })}
                {!custom && (
                    <LabelIcons
                        label={label}
                        iconShape={iconShape}
                        required={required}
                        renderInput={renderInput}
                        errorText={errorText}
                        disabled={disabled}
                        inputId={inputId}
                        isValid={state.isValid}
                        hasDropdown={hasDropdown}
                        editable={editable && state.isReadOnly}
                        readOnlyHandler={handleReadOnlyState}
                    />
                )}
            </FlosFormGroup>
        );
    }
);

export { FlosField, FlosFieldProps, FlosFieldRenderInputProps };
export default FlosField;
