import classnames from 'classnames';
import * as React from 'react';
import { BoxListButtonContainer } from './box-list-button-container';
import { BoxListIcon } from './box-list-icon';
import { BoxListStatus } from './box-list-status';
import { BoxListTitleGroup } from './box-list-title-group';

type DivProps = React.HTMLAttributes<HTMLDivElement>;

interface IBoxListItemProps extends Omit<DivProps, 'title'> {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
    button?: React.ReactNode;
    iconShape?: string;
    status?: React.ReactNode;
}
/**
 * `BoxListItem` renders `title`, `subtitle`, `Button`, `status`, `iconHref` (for icon) and any children
 * Use you need to customize the subcomponent, compose this with `BoxListTitleGroup`, `BoxListButtonContainer`, `BoxListIcon`, and `BoxListStatus`.
 */
export const BoxListItem: React.FC<IBoxListItemProps> = ({ className, title, subtitle, button, iconShape, status, children, ...props }) => (
    <div className={classnames('tds-BoxList-item Box', className)} {...props}>
        {(iconShape || title || subtitle) && (
            <div>
                {iconShape && <BoxListIcon shape={iconShape} />}
                {(title || subtitle) && <BoxListTitleGroup title={title} subtitle={subtitle} />}
            </div>
        )}
        {button && <BoxListButtonContainer>{button}</BoxListButtonContainer>}
        {children}
        {status && <BoxListStatus>{status}</BoxListStatus>}
    </div>
);

export default BoxListItem;
