import classnames from 'classnames';
import * as React from 'react';

interface IHeaderProps {
    /** variant of the header */
    variant?: 'dark' | 'alt' | 'grey' | 'dim';
    body?: React.ReactNode;
}
/**
 * Header component wrap the children in a banner-like container
 * @deprecated Renamed for consistency. Use PageHeader instead
 */
export const Header: React.FunctionComponent<IHeaderProps> = ({ variant, body, children }) => (
    <div className={classnames('PageHeader', variant && `PageHeader--${variant}`)}>
        <div className="PageHeader-content container">
            <div className="row">
                <div className="col-md-12">
                    <h1 className="PageHeader-title">{children}</h1>
                    {body && <div className="PageHeader-body">{body}</div>}
                </div>
            </div>
        </div>
    </div>
);
