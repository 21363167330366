import React from 'react';
import cx from 'classnames';
import { useId } from '../../../utils/hooks';

type BadgeProps = {
    /**
     * The content of the badge
     */
    content: string;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Badge is a circle dot with a number or char inside. It supports up to 2 digits/chars - will not render anything when above.
 */

const Badge = ({ content, ...props }: BadgeProps) => {
    const id = useId(props.id);

    return (
        <>
            {content.length < 3 && (
                <div id={id} className={cx('flos-badge', props.className && props.className)} {...props}>
                    {content}
                </div>
            )}
        </>
    );
};

export type { BadgeProps };
export { Badge };
