import * as React from 'react';
import { AnimateHeight } from '../../accordion/animate-height/animate-height';
import { ListBoxContext } from './list-box-context';
import cx from 'classnames';

export interface IListBoxPanelProps {
    /** unique id for this section. MUST be unique within a `ListBox` component */
    id: string;
    'aria-labelledby'?: string;
    className?: string;
    /** The label text on the show/hide link */
    label: React.ReactNode;
    /** The label text on the show/hide link when the controlled section is expanded. If not specified the content will use the `label` prop */
    expandedLabel?: React.ReactNode;
}

/**
 * `ListBoxPanel` is a component that must be used within a `ListBox` component.
 * It will be controlled by `DetailsBoxToggleButton` with `sectionId` equal to `id` of this component.
 * @deprecated Use flos-list-box
 */
export const ListBoxPanel: React.FC<IListBoxPanelProps> = ({ id, label, expandedLabel, children, className, ...rest }) => (
    <ListBoxContext.Consumer>
        {({ expandedSectionId }) => {
            const expanded = expandedSectionId === id;

            return (
                <AnimateHeight
                    duration={250}
                    height={expanded ? 'auto' : 0}
                    easing="ease-in-out"
                    {...{
                        'aria-expanded': expanded,
                        className: 'collapse-details',
                    }}
                    {...rest}
                >
                    <div className={cx('Box--wrapper', className)}>{children}</div>
                </AnimateHeight>
            );
        }}
    </ListBoxContext.Consumer>
);

export default ListBoxPanel;
