import * as React from 'react';
import cx from 'classnames';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { ButtonProps } from '../../../core/button/button';

type CommonProps = {
    children?: React.ReactNode | string;
};

type iconProps = { hasIcon?: false | undefined; iconSize?: never } | { hasIcon: true; iconSize?: 'xlarge' | 'large' | 'medium' | 'small' | 'xsmall' | number };

type variants = 'link' | undefined;

type VariantProps =
    | { variant?: variants; theme?: 'logo' | 'external' | 'primary'; buttonVariant?: never; size?: never; minWidth?: never; disabled?: never }
    | {
          variant: 'button';
          theme?: never;
          buttonVariant?: ButtonProps['variant'];
          size?: ButtonProps['size'];
          minWidth?: ButtonProps['minWidth'];
          disabled?: ButtonProps['disabled'];
      };

type FlosLinkProps = CommonProps & iconProps & VariantProps & React.ComponentPropsWithoutRef<'a'>;

const FlosLink = ({ hasIcon, iconSize, className, children, theme, variant = 'link', buttonVariant, size, minWidth, disabled, ...props }: FlosLinkProps): React.ReactElement => {
    const classes = [];
    const linkAttributes = {
        ...props,
        ...(disabled && {
            'aria-disabled': true,
        }),
    };
    classes.push('flos-link');
    theme && classes.push('flos-link--' + theme);
    if (variant && variant == 'button') {
        classes.push('flos-button');
        buttonVariant && buttonVariant == 'secondary' && classes.push('flos-button--secondary');
        size && classes.push(`flos-button--${size}`);
        minWidth && classes.push(`flos-button--${minWidth}`);
    }
    hasIcon && (!variant || variant != 'button') && classes.push('flos-link--has-icon');
    return (
        <a className={cx(...classes, className)} {...linkAttributes}>
            <span>{children}</span>
            {hasIcon && <FlosIcon shape={theme === 'external' ? 'launch' : 'arrow-right'} size={iconSize || 'xsmall'} className={cx('flos-link-icon')} />}
        </a>
    );
};

export type { FlosLinkProps };
export { FlosLink };
export default FlosLink;
