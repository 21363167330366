const showBelow = (startElemBounding: DOMRect, listElemBounding: DOMRect) => {
    if (startElemBounding && listElemBounding) {
        const hasSpaceBelow = (window.innerHeight || document.documentElement.clientHeight) - startElemBounding.bottom >= listElemBounding.height;
        const hasSpaceAbove = startElemBounding.top >= listElemBounding.height;
        return hasSpaceBelow || !hasSpaceAbove;
    }
    return true;
};

const getHorizontalPos = (startElemBounding: DOMRect, listElemBounding: DOMRect) => {
    if (startElemBounding && listElemBounding) {
        let horizontalPosition: string = 'middle';

        if (startElemBounding.right >= listElemBounding.width) horizontalPosition = 'left';
        else if ((window.innerWidth || document.documentElement.clientWidth) - startElemBounding.right >= listElemBounding.width) horizontalPosition = 'right';

        return horizontalPosition;
    }
    return true;
};

export const getDropdownPositionParams = (buttonElement: HTMLElement, selectElement: HTMLElement, listElement: HTMLElement, size: string) => {
    const startElement = buttonElement.lastElementChild !== null ? buttonElement.lastElementChild : buttonElement;
    const startElemBounding = startElement.getBoundingClientRect();
    const selectElemBounding = selectElement.getBoundingClientRect();
    const listElemBounding = listElement.getBoundingClientRect();
    const showOnTop = !showBelow(startElemBounding, listElemBounding);
    const horizontalPos = getHorizontalPos(startElemBounding, listElemBounding);

    return {
        verticalTopPos: showOnTop ? 'unset' : selectElemBounding.height * -0.5,
        verticalBottomPos: showOnTop ? selectElemBounding.height * 0.5 : 'unset',
        horizontalLeftPos: horizontalPos === 'right' ? selectElemBounding.width - (selectElemBounding.right - startElemBounding.right) - 0.5 * startElemBounding.width : 'unset',
        horizontalRightPos:
            horizontalPos === 'right'
                ? 'unset'
                : horizontalPos === 'middle'
                ? size === 'large'
                    ? 10
                    : ((window.innerWidth || document.documentElement.clientWidth) - listElemBounding.width) * -1
                : selectElemBounding.right - startElemBounding.right + (horizontalPos === 'right' ? -0.5 * startElemBounding.width : 0.5 * startElemBounding.width), // show left
    };
};
