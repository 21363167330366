import classnames from 'classnames';
import * as React from 'react';

interface IBoxListButtonContainerProps extends React.HTMLAttributes<HTMLDivElement> {}
/**
 * `BoxListButtonContainer` position Button on the right side of the `BoxListItem`.
 * Requires `BoxList` with props hasButton = true
 */
export const BoxListButtonContainer: React.FC<IBoxListButtonContainerProps> = ({ className, ...props }) => (
    <div className={classnames('tds-BoxList-button', className)} {...props} />
);

export default BoxListButtonContainer;
