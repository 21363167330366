import React, { ReactElement } from 'react';
import cx from 'classnames';
import { TooltipLabeled } from '../../core/forms/tooltip-labeled/tooltip-labeled';

type BoxProps = {
    title?: string | React.ReactElement;
    toolTipContent?: React.ReactNode;
    actionArea?: React.ReactNode;
    children?: React.ReactNode;
    padding?: 'small' | 'large' | 'xlarge' | 'xxlarge';
    theme?: 'light' | 'brand' | 'transparent';
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>;

const ConditionalWrapper = ({ children, condition, wrapper }: any) => (condition ? wrapper(children) : children);

const Box = ({ title, padding, theme, className, toolTipContent, actionArea, children, ...props }: BoxProps): ReactElement => {
    const classes = [];
    classes.push('flos-box');
    theme && classes.push('flos-box--theme-' + theme);
    const paddingMap = { small: 's', medium: 'm', large: 'l', xlarge: 'xl', xxlarge: 'xxl' };
    const paddingClass = padding && 'u-spacing-inset-' + paddingMap[padding];
    paddingClass && classes.push(paddingClass);
    (title || toolTipContent || actionArea) && classes.push('flos-box--flex');
    title && classes.push('flos-box--has-title');
    toolTipContent && classes.push('flos-box--has-tooltip');
    actionArea && classes.push('flos-box--has-action-area');
    className && classes.push(className);
    const offset = paddingMap[padding || 'medium'];

    return (
        <div className={cx(...classes)} {...props}>
            <ConditionalWrapper condition={title && toolTipContent} wrapper={(children: any) => <div className={'flos-box-header'}>{children}</div>}>
                {title && <div className={cx('h6', 'flos-box-title', 'flos-box-offset-' + offset + '-top-left')}>{title}</div>}
                {toolTipContent && (
                    <div className={cx('flos-box-tooltip', 'flos-box-offset-' + offset + '-top-right')}>
                        <TooltipLabeled label="">{toolTipContent}</TooltipLabeled>
                    </div>
                )}
            </ConditionalWrapper>
            {children}
            {actionArea && <div className={cx('flos-box-action-area', 'flos-box-offset-' + offset + '-bottom-right')}>{actionArea}</div>}
        </div>
    );
};

export type { BoxProps };
export { Box };
export default Box;
