import classnames from 'classnames';
import * as React from 'react';
import { callAll } from '../../../utils/fp';
import { ListBoxContext } from './list-box-context';

export interface IListBoxPanelLinkProps extends React.DetailedHTMLProps<React.AnchorHTMLAttributes<HTMLAnchorElement>, HTMLAnchorElement> {
    /** id of the section that is controlled by this Link */
    sectionId: string;
    /** content of the Link */
    label: React.ReactNode;
    /** content of the Link when the controlled section is expanded. If not specified the content will use `label` props */
    expandedLabel?: React.ReactNode;
    callBack: (sectionId: string) => void;
}

/**
 * `ListBoxPanelLink` is a component that must be used within a `ListBox` component.
 *  Other than `sectionId`, `label`, and `expandedLabel`, it also accepts all props an `<a>` accepts.
 * @deprecated Use flos-list-box
 */
export const ListBoxPanelLink: React.FC<IListBoxPanelLinkProps> = ({ sectionId, label, expandedLabel, className, onClick, callBack, ...props }) => (
    <ListBoxContext.Consumer>
        {({ expandedSectionId }) => {
            const isExpanded = expandedSectionId === sectionId;
            const displayedLabel = isExpanded ? expandedLabel || label : label;

            return (
                <a
                    style={{ marginRight: '10px', whiteSpace: 'nowrap', display: 'inline' }}
                    href={`#${sectionId}`}
                    className={classnames(className)}
                    aria-expanded={isExpanded}
                    onClick={callAll((ev) => {
                        ev.preventDefault();
                        callBack(isExpanded ? '' : sectionId);
                    }, onClick)}
                    {...props}
                >
                    {displayedLabel}
                    {isExpanded ? <flos-icon size={17} shape="arrow-up" /> : <flos-icon size={17} shape="arrow-down" />}
                </a>
            );
        }}
    </ListBoxContext.Consumer>
);
