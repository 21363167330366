import cx from 'classnames';
import * as React from 'react';
import { FlosIcon, FlosIconProps } from '../../../core/icon/flos-icon';

/**
 * `BoxListIcon` position icon on the right side of the `BoxListItem`.
 * Requires `BoxList` with props hasIcon = true
 *
 * It use [`FlosIcon`](#flosicon) under the hood.
 */
export const BoxListIcon = ({ className, ...props }: FlosIconProps) => <FlosIcon size={56} {...props} className={cx('tds-BoxList-icon', className)} />;
