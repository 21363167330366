import * as React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { NumberInput, NumberInputProps } from './number-input';

export type NumberFieldProps = NumberInputProps & FlosFieldProps;

/**
 * NumberField is a composition of `Field` and `NumberInput` component.
 * If you wish to have more control, you can compose them yourself.
 */
export const NumberField = React.forwardRef<HTMLInputElement, NumberFieldProps>(function NumberFieldComp(
    { label, wrapperClassName, iconShape, errorText = 'er ikke korrekt udfyldt', isValid, editable, onValidityChange, ...inputProps },
    ref
) {
    return (
        <FlosField
            ref={ref}
            id={inputProps.id}
            isValid={isValid}
            onValidityChange={onValidityChange}
            editable={editable}
            label={label}
            wrapperClassName={wrapperClassName}
            iconShape={iconShape}
            errorText={errorText}
            required={inputProps.required}
            inputId={inputProps.id}
            disabled={inputProps.disabled}
            renderInput={({ getInputProps }) => <NumberInput {...getInputProps({ ...inputProps, ref })} />}
        />
    );
});
