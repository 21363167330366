import classnames from 'classnames';
import * as React from 'react';

const imgSrcMap = {
    giant: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-200.gif',
    large: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-64.gif',
    medium: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-32.gif',
    small: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-16.gif',
};

const imgSrcSetMap = {
    large: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-64.gif 1x, https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-128.gif 2x',
    medium: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-32.gif 1x, https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-64.gif 2x',
    small: 'https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-16.gif 1x, https://flos.topdanmark.dk/core-cvi/latest/images/loading/loading-32.gif 2x',
};

const imgStyleMap: { [key in NonNullable<LoadingSpinnerProps['size']>]: React.CSSProperties } = {
    giant: {
        height: 200,
        width: 200,
    },
    large: {
        height: 64,
        width: 64,
    },
    medium: {
        height: 32,
        width: 32,
    },
    small: {
        height: 16,
        width: 16,
    },
};

export type LoadingSpinnerProps = {
    /** size of the spinner, default to large */
    size?: 'small' | 'medium' | 'large' | 'giant';
    /** whether the spinner should be absolutely positioned, default to true */
    absolute?: boolean;
    wrapperClassName?: string;
    /** delay time (in milisecond) before rendering the spinner after mount. Default to 0 */
    delayBeforeAppear?: number;
} & JSX.IntrinsicElements['img'];
/**
 * `LoadingSpinner` is used as indicator of loading state.
 * Other than `size`, `absolute`, and `wrapperClassName`, it also accepts all props a `<img>` tag accepts
 * @deprecated
 */
export const LoadingSpinner = ({ size = 'large', absolute = true, wrapperClassName, delayBeforeAppear = 0, ...imageProps }: LoadingSpinnerProps) => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (!show) {
            const timeoutId = window.setTimeout(() => setShow(true), delayBeforeAppear);

            return () => window.clearTimeout(timeoutId);
        }
        return;
    }, [show, delayBeforeAppear]);

    return show ? (
        <div className={classnames('Loading', absolute && 'Loading--abs', wrapperClassName)}>
            <img className="Loading-img" src={imgSrcMap[size]} srcSet={imgSrcSetMap[size]} style={imgStyleMap[size]} alt="Vent venligst..." {...imageProps} />
        </div>
    ) : null;
};
