import * as React from 'react';

type TabProps = {
    label: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

const Tab: React.FC<TabProps> = ({ children, id }) => {
    return (
        <div className={'flos-tab-panel'} id={`${id}-panel`} role="tabpanel" aria-labelledby={`${id}-button`}>
            {children}
        </div>
    );
};

export type { TabProps };
export { Tab };
export default Tab;
