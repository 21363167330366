import * as React from 'react';
import { AnimateHeight } from './animate-height/animate-height';
import { noop } from '../../utils';
import { useId } from '../../utils/hooks';
import cx from 'classnames';
import FlosIcon from '../../core/icon/flos-icon';

export interface IAccordionPanelProps {
    /**
     * The title of the accordion pane
     */
    title: React.ReactNode;
    /**
     * If you need to provide more information about the content
     */
    subtitle?: string;
    /**
     * Callback that will report of the panel is open or closed
     * @param isExpanded
     */
    onToggle?: (isExpanded: boolean) => void;
    /**
     * Boolean that decides if the panel is open or cloed
     */
    expanded?: boolean;
    /**
     * The id is used to keep track of the all panes in an accordion
     */
    id?: string;
    /**
     * The content of the panel. Can be anything you need.
     */
    children: React.ReactNode;
    /**
     * Secondary panels look a little different. Usually used when the panel is inside other components, like FlosListBox or andother accordion panel.
     */
    secondary?: boolean;
    /**
     * Used to render important actions or information inside the title of the pane. Could be buttons or price information.
     */
    actionArea?: React.ReactNode;
}

type InternalAccordionPanelProps = {
    /**
     * Internal prop passed by the Accordion component to let the panel trigger the open/close toggle on click.
     */
    toggle?: () => void;
};

/**
 * AccordionPanel should be used as direct child of `Accordion`
 */
export const AccordionPanel = ({ id, toggle, onToggle, expanded, secondary, title, subtitle, actionArea, children }: IAccordionPanelProps & InternalAccordionPanelProps) => {
    const internalId = useId(id);
    const triggerToggle = toggle || noop;

    return (
        <div className="Accordion-panel panel">
            <div className="panel-heading" role="tab" id={'panel-head' + internalId}>
                <a
                    onClick={(e) => {
                        e.preventDefault();
                        triggerToggle();
                    }}
                    aria-expanded={!!expanded}
                    aria-controls={'panel' + internalId}
                    role="button"
                    className={'Accordion-title ' + (expanded ? '' : ' collapsed')}
                    href="#"
                    tabIndex={0}
                >
                    <div className={cx('Accordion-title-txt')}>
                        {secondary && <FlosIcon className="Accordion-toggle-icon" shape="arrow-down" size={16} />}
                        <span>
                            {secondary ? <p>{title}</p> : <h4>{title}</h4>}
                            {subtitle && <p className="Accordion-subtitle">{subtitle}</p>}
                        </span>
                    </div>
                    {!secondary && <FlosIcon className="Accordion-toggle-icon" shape="arrow-down" size={32} />}
                    {secondary && actionArea && <div className="Accordion-secondary-action-area">{actionArea}</div>}
                </a>
            </div>
            <AnimateHeight
                id={'panel' + internalId}
                className={`Accordion-panel-collapse  ${!expanded && 'collapsed'}`}
                role="tabpanel"
                aria-labelledby={'panel-head' + internalId}
                expanded={!!expanded}
                onAnimationEnd={() => {
                    onToggle && onToggle(!!expanded);
                }}
            >
                <div className="panel-body">{children}</div>
            </AnimateHeight>
        </div>
    );
};
