import React from 'react';
import { OptionProps } from '../select-input';

type SuggestionListProps = {
    isOpen?: boolean;
    options?: OptionProps[];
    onClick: (suggestion: OptionProps) => void;
};

const flattenObject = (obj: any) => {
    const flattened = {};

    Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object' && obj[key] !== null) {
            Object.assign(flattened, flattenObject(obj[key]));
        } else {
            flattened[key] = obj[key];
        }
    });

    return flattened;
};

const renderOption = (option: OptionProps, onClick: SuggestionListProps['onClick'], index: number) => {
    return (
        <li className="typeaheadfield-suggestionlist-option" key={index}>
            <a
                href="#"
                onClick={(e) => {
                    e.preventDefault();
                    onClick(option);
                }}
                tabIndex={0}
            >
                {option.label}
            </a>
        </li>
    );
};

const renderGroupedOption = (options: OptionProps[], onClick: SuggestionListProps['onClick']) => {
    let groupedOptionOutput: any[] = [];
    let groupedIndex = 100;
    options.forEach((o: any) => {
        groupedOptionOutput.push(renderOption(o, onClick, groupedIndex));
        groupedIndex++;
    });
    return groupedOptionOutput;
};

const hasOptions = (options: OptionProps[] | undefined) => {
    return options && (options.length || Object.keys(flattenObject(options)).length);
};

const SuggestionsList = ({ isOpen, options, onClick }: SuggestionListProps) => {
    if (!isOpen) {
        return null;
    }

    let output: any[] = [];

    if (hasOptions(options)) {
        let index = 0;
        for (const key in options) {
            if (options.hasOwnProperty(key)) {
                if (isNaN(parseInt(key))) {
                    // Option groups will be a string
                    const groupedOptionOutput = renderGroupedOption(options[key], onClick);
                    groupedOptionOutput.length &&
                        output.push(
                            <li tabIndex={0} key={index}>
                                <p className="typeaheadfield-suggestionlist-options-group-header">{key}</p>
                                <ul className="typeaheadfield-suggestionlist-options-group">{groupedOptionOutput}</ul>
                            </li>
                        );
                } else {
                    output.push(renderOption(options[key], onClick, index));
                }
                index++;
            }
        }
    } else {
        output.push(
            <li key={0} tabIndex={0}>
                Ingen match
            </li>
        );
    }

    return (
        <div className="typeaheadfield-suggestionlist" role="dialog" tabIndex={-1}>
            <ul className="typeaheadfield-suggestionlist-options">{output}</ul>
        </div>
    );
};

export type { SuggestionListProps };
export { SuggestionsList };
export default SuggestionsList;
