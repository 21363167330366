import cx from 'classnames';
import * as React from 'react';

export type ButtonToolbarProps = JSX.IntrinsicElements['div'];

/**
 *
 * @param props
 * @deprecated Use ButtonGroup instead
 */
export const ButtonToolbar = (props: ButtonToolbarProps) => <div {...props} className={cx('flos-button-toolbar', props.className)} />;
