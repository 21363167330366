import { useEffect, useRef, useState } from 'react';

/**
 * `UseInterval` allows you to invoke some operations (e.g. polling) with a fixed interval.
 *
 * @param callback the callback you want to invoke for each interval. This callback can returns a cleanup function.
 * @param delay pass down `null` to pause the interval
 * @return function to reset the current interval
 */
export function useInterval(callback: (() => void) | (() => () => void), delay: number | null) {
    const savedCallback = useRef<typeof callback | null>(null);
    const [resetCount, setResetCount] = useState(0);

    // Remember the latest function.
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
        let cleanup: void | (() => void);
        function tick() {
            if (savedCallback.current) {
                cleanup = savedCallback.current();
            }
        }
        if (delay === null) {
            return;
        }

        const id = window.setInterval(tick, delay);
        return () => {
            window.clearInterval(id);
            if (cleanup) {
                cleanup();
            }
        };
    }, [delay, resetCount]);

    return function reset() {
        setResetCount((lastCount) => lastCount + 1);
    };
}
