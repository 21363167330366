import React from 'react';
import cx from 'classnames';

type CardProps = {
    imageUrl?: string;
    imagePosition?: 'top-right';
    theme?: 'primary' | 'secondary' | 'transparent';
    variant?: 'small' | 'medium';
    title?: string;
    text?: string;
    /**
     * This label will show above the title
     */
    label?: string;
    children?: React.ReactNode;
    actionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

const Card = ({ imageUrl, theme, variant, imagePosition, title, text, label, children, actionArea, className, ...props }: CardProps) => {
    return (
        <div className={cx('card', theme && 'card--' + theme, variant && 'card--' + variant, imagePosition && 'card--' + imagePosition, className)} {...props}>
            {imageUrl && (
                <div className="card-image">
                    <img alt="" src={imageUrl} />
                </div>
            )}
            <div className={cx('card-content', !imageUrl && 'card-no-image')}>
                <div className={'card-text'}>
                    {label && <div className={'h5 card-label'}>{label}</div>}
                    {title && <div className={cx(variant === 'small' ? 'h4' : 'h2', 'card-title', !text && !children && 'u-spacing-stack-none')}>{title}</div>}
                    {text && <p className={cx(!children && 'u-spacing-stack-none')}>{text}</p>}
                    {children && <div className="card-children">{children}</div>}
                </div>
                {actionArea && <div className={'card-action'}>{actionArea}</div>}
            </div>
        </div>
    );
};

export type { CardProps };
export { Card };
export default Card;
