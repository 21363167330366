import React, { ReactElement, Children, cloneElement } from 'react';
import cx from 'classnames';
import { FlosLink } from '../../navigation/flos-link/flos-link';
import { FlosIcon } from '../../../core/icon/flos-icon';

type BoxLinkGroupProps = {
    stacked?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

type BoxLinkListProps = {} & React.ComponentPropsWithoutRef<'ul'>;

type BoxLinkProps = {
    title: string;
    description?: React.ReactNode;
    iconShape?: string;
    iconVariant?: 'rounded';
    theme?: 'highlight' | 'transparent';
    variant?: 'external';
} & React.ComponentPropsWithoutRef<'a'>;

const BoxLinkGroup = ({ children, stacked, ...props }: BoxLinkGroupProps): ReactElement => {
    const arrayChildren = Children.toArray(children);
    return (
        <div className={cx(stacked ? 'boxlink-stack' : 'flos-grid boxlink-group')} {...props}>
            {Children.map(arrayChildren, (child: any) => {
                return <div className={cx('boxlink-wrapper', !stacked && 'boxlink-wrapper--grouped')}>{cloneElement(child as any)}</div>;
            })}
        </div>
    );
};

const BoxLinkList = ({ children, className, ...props }: BoxLinkListProps): ReactElement => {
    const arrayChildren = Children.toArray(children);
    return (
        <ul className={cx('boxlink-list', className)} {...props}>
            {Children.map(arrayChildren, (child: any) => {
                return <li>{cloneElement(child as any)}</li>;
            })}
        </ul>
    );
};

const BoxLink = ({ iconShape, iconVariant, title, description, className, theme, variant, ...props }: BoxLinkProps): ReactElement => {
    return (
        <FlosLink
            hasIcon={true}
            theme={variant === 'external' ? 'external' : 'primary'}
            className={cx('Box boxlink u-flex-box u-flex-box--nowrap u-flex-box--center u-flex-box--space-between', theme && `boxlink--${theme}`, className && className)}
            {...props}
        >
            <div className={'u-flex-box u-flex-box--nowrap u-flex-box--center u-flex-box--gap-small'}>
                {iconShape && (
                    <FlosIcon
                        className={cx(iconVariant === 'rounded' ? 'flos-icon--highlight' + (theme === 'highlight' ? '-secondary' : '') : '', 'boxlink-icon')}
                        shape={iconShape}
                        size={iconVariant == 'rounded' ? 'xsmall' : 'medium'}
                    />
                )}
                <div>
                    <span>{title}</span>
                    {description && <p className={'small'}>{description}</p>}
                </div>
            </div>
        </FlosLink>
    );
};

export type { BoxLinkProps };
export { BoxLink, BoxLinkGroup, BoxLinkList };
export default BoxLink;
