import cx from 'classnames';
import * as React from 'react';
import { useId } from '../../../utils/hooks';
import { isBool } from '../../../utils/typeguard';
import { RadioFieldContext } from './radio-field-context';

export interface RadioInputProps extends Omit<JSX.IntrinsicElements['input'], 'value'> {
    /** The label of the radio button, the bounding box defines the clickable area of the field. */
    label: React.ReactNode;
    /** To provide more information about the choice. Rendered inside the label, so it will also be clickable. */
    description?: React.ReactNode;
    /** The value to pass on when the form is submitted or change event is fired. */
    value: string | number | boolean;
    /** validation status of the field */
    isValid?: boolean;
    /** Disables the field */
    disabled?: boolean;
    /** Mark field as required */
    required?: boolean;
    /** Error message */
    errorText?: string;
}

/**
 * `RadioInput` can be used in conjection with `RadioField` or as standalone
 *  When used as standalone, you can pass down any props a radio input accepts and it would just works.
 */
export const RadioInput: React.FC<RadioInputProps> = ({
    label,
    description,
    onChange,
    checked: checkedProp,
    id: providedId,
    value,
    isValid = undefined,
    disabled,
    defaultChecked,
    required,
    errorText,
    ...inputProps
}) => {
    const id = useId(providedId);

    const { value: contextValue, name, isValid: valid, variant, disabled: isDisabled, errorText: hasErrorText } = React.useContext(RadioFieldContext);

    let checked = checkedProp;

    const changeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        onChange && onChange(e);
    };

    if (typeof checked === 'undefined') {
        checked = value === contextValue;
    }
    if (defaultChecked) {
        checked = true;
    }

    let radioStatus: string | null = null;
    if (isValid === true || valid === true) radioStatus = 'has-success';
    if (isValid === false || valid === false) radioStatus = 'has-error';

    const isButton = variant === 'button-toolbar' || variant === 'button-menu';
    const baseClass = isButton ? 'flos-button flos-button--outline' : 'flos-radio';
    const inputNode = (
        <input
            value={!isBool(value) ? value : undefined} // we pass down value only if it's not boolean because boolean is not valid input value
            type="radio"
            id={id}
            className="visuallyhidden"
            onChange={changeHandler}
            checked={checked}
            name={name}
            required={required}
            {...inputProps}
        />
    );

    const Label = () => {
        const LabelContent = () => (
            <>
                {!(disabled || isDisabled) && (errorText || hasErrorText) && (valid === false || isValid === false) && (
                    <p className="small flos-radio-input-error-text">{errorText || hasErrorText}</p>
                )}
                <p className="u-No-bottom-margin">
                    {label}
                    {required && '*'}
                </p>
                {description && (
                    <p className="u-No-bottom-margin">
                        <small>{description}</small>
                    </p>
                )}
            </>
        );
        return (
            <label htmlFor={id} className={cx(baseClass, (disabled || isDisabled) && 'is-disabled', radioStatus, isButton && checked && 'active')}>
                {isButton ? (
                    <LabelContent />
                ) : (
                    <React.Fragment>
                        <div className="dot" />
                        <div>
                            <LabelContent />
                        </div>
                    </React.Fragment>
                )}
            </label>
        );
    };

    return (
        <>
            {inputNode}
            <Label />
        </>
    );
};
