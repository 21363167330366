import * as React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { FlosCheckbox, FlosCheckboxProps } from './flos-checkbox';

export type CheckboxFieldProps = FlosCheckboxProps & Omit<FlosFieldProps, 'label'>;

/**
 * CheckboxField is a composition of `Field` and `Input` component.
 * If you wish to have more control, you can compose them yourself.
 */
export const CheckboxField = React.forwardRef<HTMLInputElement, CheckboxFieldProps>(({ iconShape, isValid, errorText, ...inputProps }, ref) => {
    return (
        <FlosField
            ref={ref}
            isValid={isValid}
            custom={true}
            label={''}
            errorText={errorText}
            required={inputProps.required}
            disabled={inputProps.disabled}
            id={inputProps.id}
            iconShape={undefined}
            renderInput={({ getInputProps }) => <FlosCheckbox {...getInputProps({ ...inputProps, ref })} errorText={isValid === false ? errorText : ''} />}
        />
    );
});
