import * as React from 'react';
import cx from 'classnames';
import Button from '../../../core/button/button';

type TabTitleProps = {
    label: React.ReactNode;
    index: number;
    isSelected: boolean;
    setSelectedTab: (index: number) => void;
} & React.ComponentPropsWithoutRef<'li'>;

const TabTitle: React.FC<TabTitleProps> = ({ label, setSelectedTab, index, isSelected, id, ...props }) => {
    const titleRef = React.useRef<HTMLLIElement | null>(null);

    const onClickHandler = (event: any) => {
        setSelectedTab(index);
        props.onClick && props.onClick(event);
        titleRef?.current?.scrollIntoView({
            behavior: 'smooth',
            block: 'center',
            inline: 'start',
        });
    };
    return (
        <li className={cx('flos-tabs-list-item', isSelected && 'active')} ref={titleRef} id={id} {...props}>
            <Button
                aria-controls={`${id}-panel`}
                id={`${id}-button`}
                aria-selected={isSelected}
                minWidth={'wider'}
                variant={'secondary'}
                role="tab"
                onClick={(event) => onClickHandler(event)}
            >
                {label}
            </Button>
        </li>
    );
};

export type { TabTitleProps };
export { TabTitle };
export default TabTitle;
