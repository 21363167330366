import * as React from 'react';
import { FlosInput, FlosInputProps } from '../input/flos-input';
import { useEffect, useState } from 'react';
import { noop } from '../../../utils';
import { validatePhone } from '../../../utils/validate';
import { default as NumberFormat } from 'react-number-format';

export type PhoneInputProps = {
    /** Should allow international phone formats disables validation */
    allowInternational?: boolean;
    /** Callback with Phone is valid */
    isValidHandler?: (isValid?: boolean) => void;
    isValid?: boolean;
} & FlosInputProps;

/**
 * PhoneInput is a wrapper around FlosInput component with formatting and validity check using react-number-format
 */
export const PhoneInput = React.forwardRef<HTMLInputElement, PhoneInputProps>(
    ({ value, isValid, onChange, onBlur, allowInternational, isValidHandler = noop, ...inputProps }, ref) => {
        const [internalValue, setInternalValue] = useState('');

        const integerAccept = /\d+/g;
        const parseInteger = (value: string) => (value.match(integerAccept) || []).join('').replace(/\s/g, '');

        const sanitizeValue = (value: string) => value && (allowInternational ? value : value && parseInteger(value));

        const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
            if (event.target.value === '' && (isValid || typeof isValid === 'undefined')) {
                isValidHandler(undefined);
            } else if (event.target.value) {
                setInternalValue(sanitizeValue(event.target.value));
                isValidHandler(validatePhone(sanitizeValue(event.target.value), allowInternational));
            } else {
                isValidHandler(false);
            }
            onBlur && onBlur(event);
        };

        const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
            const newValue = sanitizeValue(event.target.value);
            setInternalValue(newValue);
            event.target.value = newValue;
            onChange && onChange(event);
        };

        useEffect(() => {
            if (value) {
                setInternalValue(sanitizeValue(value as string));
            }
        }, [value]);

        return (
            <NumberFormat
                {...(inputProps as any)}
                getInputRef={ref}
                value={sanitizeValue(internalValue)}
                format={allowInternational ? undefined : '## ## ## ##'}
                customInput={FlosInput}
                onChange={handleChange}
                onBlur={handleBlur}
            />
        );
    }
);
