import * as React from 'react';
import { AccordionPanel, IAccordionPanelProps } from './accordion-panel';
import cx from 'classnames';

/**
 * Accordion properties.
 */
export interface IAccordionProps {
    body?: string;
    /**
     * Alternatively, you use `AccordionPanel` as direct children of this component.
     */
    panels?: IAccordionPanelProps[];
    activeIndex?: string;
    emitIndex?: (value: string) => void;
    secondary?: boolean;
    children?: React.ReactNode;
}

export interface IAccordionState {
    activeIndex: string;
}

/**
 * @version 1.1.0
 * @see https://styleguide.topdanmark.dk/components/detail/accordion--default.html
 */
export class Accordion extends React.Component<IAccordionProps, IAccordionState> {
    constructor(props: IAccordionProps) {
        super(props);
        this.state = {
            activeIndex: props.activeIndex ?? '',
        };
    }

    componentDidUpdate = (prevProps: IAccordionProps) => {
        if (this.props.activeIndex && this.props.activeIndex !== prevProps.activeIndex) {
            this.setState({ activeIndex: this.props.activeIndex ?? '' });
        }
    };

    handleToggle = (id: string) => {
        const newActiveIndex = this.state.activeIndex === id ? '' : id;
        this.setState({ activeIndex: newActiveIndex });
        this.handleEmitIndex(newActiveIndex);
    };

    handleEmitIndex = (id: string) => {
        const { emitIndex } = this.props;
        if (emitIndex) {
            emitIndex(id);
        }
    };

    render() {
        const props = this.props;

        return (
            <div className={cx('Accordion', props.secondary && 'Accordion--secondary')} role="tablist" aria-multiselectable="true">
                {props.panels
                    ? props.panels.map((panel, index) => {
                          const id = index.toString();
                          return (
                              <AccordionPanel
                                  key={index}
                                  toggle={() => this.handleToggle(id)}
                                  onToggle={panel.onToggle && panel.onToggle}
                                  expanded={id === this.state.activeIndex}
                                  title={panel.title}
                                  id={id}
                                  subtitle={panel.subtitle}
                                  secondary={props.secondary}
                              >
                                  {panel.children}
                              </AccordionPanel>
                          );
                      })
                    : React.Children.map(props.children, (child, index) => {
                          const id = index.toString();

                          return (
                              child &&
                              React.cloneElement(child as any, {
                                  expanded: id === this.state.activeIndex ? 1 : 0,
                                  toggle: () => this.handleToggle(id),
                                  onToggle: (isExpanded: boolean) => (child as any).props.onToggle && (child as any).props.onToggle(isExpanded),
                              })
                          );
                      })}
            </div>
        );
    }
}
