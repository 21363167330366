import * as React from 'react';
import { AnimateHeight } from '../../accordion/animate-height/animate-height';
import { CoverageBoxContext } from './coverage-box-context';

export interface ICoverageBoxDropdownSectionProps {
    /** unique id for this section. MUST be unique within a `CoverageBox` component */
    id: string;
    'aria-labelledby'?: string;
}

/**
 * `CoverageBoxDropdownSection` is a component that must be used within a `CoverageBox` component.
 * It will be controlled by `CoverageBoxToggleButton` with `sectionId` equal to `id` of this component.
 * @deprecated Use `ListBoxPanel`
 */
export const CoverageBoxDropdownSection: React.FC<ICoverageBoxDropdownSectionProps> = (props) => (
    <CoverageBoxContext.Consumer>
        {({ expandedSectionId }) => {
            const expanded = expandedSectionId === props.id;

            return (
                <AnimateHeight
                    duration={250}
                    height={expanded ? 'auto' : 0}
                    easing="ease-in-out"
                    {...{
                        'aria-expanded': expanded,
                        className: 'collapse-details',
                    }}
                    {...props}
                >
                    <div className="flos-ResponsiveCoverage-content">
                        <section className="flos-ResponsiveCoverage-dropdown-section">{props.children}</section>
                    </div>
                </AnimateHeight>
            );
        }}
    </CoverageBoxContext.Consumer>
);

export default CoverageBoxDropdownSection;
