import cx from 'classnames';
import * as React from 'react';
import { useState, Children, cloneElement, KeyboardEvent, ReactElement } from 'react';
import Button from '../../../core/button/button';
import { getDropdownPositionParams } from './dropdown-position';
import { FlosInput } from '../../../core/forms/input/flos-input';

type DropdownProps = {
    /**
     * Label of the dropdown. If size=small => no label will be shown. Use the onChange callback to show the value somewhere.
     **/
    label?: string;
    children?: ReactElement<DropdownItemProps> | ReactElement<DropdownItemProps>[];
    disabled?: boolean;
    /**
     * Selected value. If size=small => no value will be shown.
     **/
    value?: string;
    onChange?: any;
    /**
     * Size of the dropdown - will render dropdown in different size variants. If small  -> no label/selected value will be shown.
     **/
    size?: 'small' | 'medium' | 'large';
    /**
     * Position of the arrow (only applicable for medium/small size)
     **/
    arrowPosition?: 'left' | 'right';
} & Omit<React.ComponentPropsWithRef<'button'>, 'onChange'>;

type DropdownItemProps = {
    /**
     * Value of the drop down item
     **/
    value?: string;
    selected?: boolean;
    children?: React.ReactNode;
    onClick?: React.MouseEventHandler<HTMLLIElement>;
    onKeyDown?: React.KeyboardEventHandler<HTMLLIElement>;
};

const Dropdown = React.forwardRef<HTMLButtonElement, DropdownProps>(
    ({ label, children, onChange, className, size = 'large', arrowPosition = 'right', ...props }: DropdownProps, ref): React.ReactElement => {
        const arrayChildren = Children.toArray(children) as any;
        const initialOption = arrayChildren.findIndex((child: any) => child.props.selected);
        const [showOptions, setShowOptions] = useState<boolean>(false);
        const [selectedOption, setSelectedOption] = useState<number | null>(initialOption >= 0 ? initialOption : null);
        const [inputValue, setInputValue] = React.useState('');
        const inputRef = (ref as React.MutableRefObject<HTMLInputElement>) || (React.useRef() as React.MutableRefObject<HTMLInputElement>);
        const listRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
        const buttonRef = React.useRef() as React.MutableRefObject<HTMLButtonElement>;
        const selectRef = React.useRef() as React.MutableRefObject<HTMLDivElement>;
        const [dropdownPositionParams, setDropdownPositionParams] = useState<{
            verticalTopPos: number | string;
            verticalBottomPos: number | string;
            horizontalLeftPos: number | string;
            horizontalRightPos: number | string;
        }>({
            verticalTopPos: 0,
            verticalBottomPos: 'unset',
            horizontalLeftPos: 0,
            horizontalRightPos: 'unset',
        });

        const toggleOptions = () => {
            // decide starting position of dropdown
            setDropdownPositionParams(getDropdownPositionParams(buttonRef.current, selectRef.current, listRef.current, size));

            setShowOptions(!showOptions);
            // scroll selected into view
            const selected = listRef.current.querySelector('li[aria-selected=true]') as HTMLElement;
            if (selected && typeof selected.scrollIntoView == 'function') {
                setTimeout(function () {
                    selected?.scrollIntoView({
                        behavior: 'smooth',
                        block: 'center',
                        inline: 'center',
                    });
                }, 100);
            }
        };

        const setAndClose = (index: number) => {
            setSelectedOption(index);
            setShowOptions(false);
        };

        const handleKeyPress = (index: number) => (e: KeyboardEvent) => {
            switch (e.key) {
                case ' ':
                case 'SpaceBar':
                case 'Enter':
                    e.preventDefault();
                    setAndClose(index);
                    break;
                default:
                    break;
            }
        };

        const handleListKeyDown = (e: KeyboardEvent) => {
            const currentOption = typeof selectedOption == 'number' ? selectedOption : -1;
            switch (e.key) {
                case 'Escape':
                    e.preventDefault();
                    setShowOptions(false);
                    break;
                case 'ArrowUp':
                    e.preventDefault();
                    currentOption - 1 >= 0 && setSelectedOption(currentOption - 1);
                    break;
                case 'ArrowDown':
                    e.preventDefault();
                    currentOption + 1 < arrayChildren.length && setSelectedOption(currentOption + 1);
                    break;
                default:
                    break;
            }
        };

        React.useEffect(() => {
            for (let i = 0; i < arrayChildren.length; i++) {
                if (arrayChildren[i].props.selected) {
                    setSelectedOption(i);
                }
            }
        }, []);

        React.useEffect(() => {
            if (typeof selectedOption == 'number') {
                setInputValue(arrayChildren[selectedOption].props.value);
            }
            const selected = listRef.current.querySelector('li[aria-selected=true]') as HTMLElement;
            if (selected && !isInParentsViewPort(selected) && typeof selected.scrollIntoView == 'function') {
                selected?.scrollIntoView();
            }
        }, [selectedOption]);

        React.useEffect(() => {
            onChange && onChange({ target: inputRef.current, currentTarget: inputRef.current });
        }, [inputValue]);

        React.useEffect(() => {
            const handleClickOutside = (e: MouseEvent) => {
                if (showOptions) {
                    if (!listRef.current.contains(e.target as Node)) {
                        setShowOptions(false);
                    }
                    return;
                }
            };
            document.addEventListener('mousedown', handleClickOutside);
            return () => {
                document.removeEventListener('mousedown', handleClickOutside);
            };
        }, [showOptions]);

        return (
            <div className={cx('flos-dropdown', 'flos-dropdown--' + size, 'flos-dropdown--' + arrowPosition, props.disabled && 'flos-dropdown--disabled', className)}>
                <div className={'flos-dropdown-select'} ref={selectRef}>
                    <FlosInput autoComplete={'off'} type="hidden" value={inputValue} ref={inputRef} />
                    {size === 'medium' && (
                        <div className={'flos-dropdown-select-label'}>
                            {label && <p className={cx('paragraph--small', !props.disabled && 'u-color-secondary-4-medium')}>{label}</p>}
                            {selectedOption !== null && <p className={'paragraph--small'}>{arrayChildren[selectedOption].props.children}</p>}
                        </div>
                    )}
                    <Button
                        className="flos-button--icon"
                        type="button"
                        aria-haspopup="listbox"
                        aria-expanded={showOptions}
                        variant="secondary"
                        onClick={toggleOptions}
                        onKeyDown={handleListKeyDown}
                        ref={buttonRef}
                        {...props}
                    >
                        {size === 'large' && (label && selectedOption == null ? label : arrayChildren[selectedOption !== null ? selectedOption : 0].props.children)}
                        <flos-icon size={16} shape="arrow-down" />
                    </Button>
                </div>
                <div className={'flos-dropdown-outer'}>
                    <div
                        ref={listRef}
                        className={cx('flos-dropdown-inner', showOptions && 'show')}
                        style={{
                            visibility: showOptions ? 'visible' : 'hidden',
                            left: dropdownPositionParams.horizontalLeftPos,
                            right: dropdownPositionParams.horizontalRightPos,
                            top: dropdownPositionParams.verticalTopPos,
                            bottom: dropdownPositionParams.verticalBottomPos,
                        }}
                    >
                        <ul role={'listbox'} className={cx('flos-dropdown-list', 'u-scrollbar')}>
                            {Children.map(children, (child: any, index: number) => {
                                return cloneElement(child as any, {
                                    selected: selectedOption == index,
                                    onClick: () => {
                                        setAndClose(index);
                                    },
                                    onKeyPress: handleKeyPress(index),
                                });
                            })}
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
);

const DropdownItem = ({ value, selected, onClick, onKeyDown, children, ...props }: DropdownItemProps): React.ReactElement => {
    return (
        <li className={'flos-dropdown-list-item'} role={'option'} tabIndex={0} value={value} aria-selected={selected} onClick={onClick} {...props}>
            {children}
        </li>
    );
};

const isInParentsViewPort = (element: HTMLElement) => {
    const parent = element?.offsetParent;
    if (parent) {
        const elemBounding = element.getBoundingClientRect();
        const parentBounding = parent.getBoundingClientRect();
        return elemBounding.bottom <= parentBounding.height + parentBounding.top && elemBounding.top >= parentBounding.top;
    }
    return false;
};

export type { DropdownProps, DropdownItemProps };
export { Dropdown, DropdownItem };
export default Dropdown;
