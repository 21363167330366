import classnames from 'classnames';
import * as React from 'react';

export type LoadingDotsProps = {
    /** Whether the spinner should be positioned in the center of the container */
    centered?: boolean;
    /** delay time (in millisecond) before rendering the spinner after mount. Default to 0 */
    delayBeforeAppear?: number;
} & JSX.IntrinsicElements['img'];

/**
 * `LoadingDots` is used as indicator of loading state.
 */
export const LoadingDots = ({ centered = true, delayBeforeAppear = 0 }: LoadingDotsProps) => {
    const [show, setShow] = React.useState(false);

    React.useEffect(() => {
        if (!show) {
            const timeoutId = window.setTimeout(() => setShow(true), delayBeforeAppear);

            return () => window.clearTimeout(timeoutId);
        }
        return;
    }, [show, delayBeforeAppear]);

    return show ? <div className={classnames('cvi-loading-dots', centered && 'cvi-loading-dots--abs')} /> : null;
};
