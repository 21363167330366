import cx from 'classnames';
import * as React from 'react';
import { useId } from '../../../utils/hooks';
import { Container } from '../../../core/layout/grid/container';
import { FlosIcon } from '../../../core/icon/flos-icon';

export type AttentionBoxProps = {
    /**
     * show the close Button for user to hide this attention Box
     */
    dismissible?: boolean;
    children: React.ReactNode;
} & JSX.IntrinsicElements['div'];

/**
 *
 * @see https://styleguide.topdanmark.dk/components/detail/attention-box.html
 */
export const AttentionBox = ({ dismissible, className, children, ...props }: AttentionBoxProps) => {
    const [hidden, setHidden] = React.useState(false);
    const confirmedId = useId(props.id);

    return hidden ? null : (
        <div className={cx('AttentionBox', className)} {...props}>
            <Container>
                <FlosIcon shape="circle-exclamation" size={30} />
                {children}
                {dismissible && (
                    <a
                        onClick={(ev) => {
                            ev.preventDefault();
                            setHidden(true);
                        }}
                        href={`#${confirmedId}-close`}
                        id={`${confirmedId}-close`}
                        className="AttentionBox-close"
                        role="button"
                    >
                        Luk &nbsp;
                        <FlosIcon shape="circle-x" size={18} />
                    </a>
                )}
            </Container>
        </div>
    );
};
