import * as React from 'react';
import DatePicker, { ReactDatePickerProps, registerLocale } from 'react-datepicker';
import da from 'date-fns/locale/da';
import { FlosField, FlosFieldProps } from '../flos-field';
import { FlosInput } from '../input/flos-input';
import cx from 'classnames';
import { useRef } from 'react';

registerLocale('da', da);

export type DatePickerFieldProps = ReactDatePickerProps & FlosFieldProps;

const CustomInput = React.forwardRef<HTMLInputElement, FlosFieldProps>(({ label, isValid, iconShape, errorText, wrapperClassName, ...flosInputProps }, ref) => {
    return (
        <FlosField
            ref={ref}
            isValid={isValid}
            label={label}
            iconShape={iconShape || 'calendar-02'}
            errorText={errorText}
            wrapperClassName={cx('u-No-bottom-margin', wrapperClassName)}
            disabled={flosInputProps.disabled}
            required={flosInputProps.required}
            id={flosInputProps.id}
            hasDropdown
            renderInput={({ getInputProps }) => <FlosInput {...getInputProps({ ...flosInputProps, ref })} autoComplete="off" />}
        />
    );
});

/**
 * DateField is a wrapper of `react-datepicker` using styling from ´FlosField´ and ´FlosInput´.
 * You can read more about ´react-datepicker´ here: https://reactdatepicker.com/
 * If you wish to have more control, you can compose them yourself.
 */
export const DateField = React.forwardRef<HTMLInputElement, DatePickerFieldProps>(
    (
        {
            filterDate,
            selected,
            dateFormat = 'dd/MM/yyyy',
            onCalendarClose,
            onCalendarOpen,
            onChangeRaw,
            minDate,
            maxDate,
            selectsStart,
            selectsEnd,
            startDate,
            endDate,
            inline,
            excludeDates,
            onChange,
            onBlur,
            ...rest
        },
        ref
    ) => {
        const inputRef = (ref as React.RefObject<HTMLInputElement>) || useRef<HTMLInputElement>(null);

        return (
            <div className={'flos-date-field'}>
                <DatePicker
                    id={rest.id}
                    inline={inline}
                    required={rest.required}
                    placeholderText={' '}
                    selected={selected}
                    onChange={onChange}
                    onChangeRaw={onChangeRaw}
                    onBlur={onBlur}
                    showPopperArrow={false}
                    locale={'da'}
                    filterDate={filterDate}
                    dateFormat={dateFormat}
                    onCalendarOpen={onCalendarOpen}
                    onCalendarClose={onCalendarClose}
                    minDate={minDate}
                    maxDate={maxDate}
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                    peekNextMonth
                    disabled={rest.disabled}
                    excludeDates={excludeDates}
                    customInput={<CustomInput {...rest} ref={inputRef} />}
                />
            </div>
        );
    }
);
