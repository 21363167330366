import * as React from 'react';
import { FlosInput, FlosInputProps } from '../input/flos-input';
import { Button, ButtonProps } from '../../button/button';
import { callAll } from '../../../utils/fp';
import cx from 'classnames';

export type Option = {
    value: string;
    disabled?: boolean;
};

export type TimeInputProps = {
    options?: Array<Option>;
    children?: React.ReactNode;
} & FlosInputProps;

export const TimeInput = React.forwardRef<HTMLInputElement, TimeInputProps>(function TimeInputComp({ options, children, onChange, ...props }, ref) {
    const [showOptions, setShowOptions] = React.useState(false);
    const [positionAbove, setPositionAbove] = React.useState(true);
    const [inputValue, setInputValue] = React.useState('');
    const [dialogPositionStyle, setDialogPositionStyle] = React.useState({});

    const dialogRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;
    const inputRef = (ref as React.MutableRefObject<HTMLInputElement>) || (React.useRef() as React.MutableRefObject<HTMLInputElement>);

    const childrenArray = React.Children.toArray(children);

    const handleInputFocus = (e: any) => {
        setShowOptions(true);
        inputRef.current = e.target;
    };
    const dialogStyle = (elementHeight: number, showOptionsAbove: boolean) => {
        if (showOptionsAbove) {
            setDialogPositionStyle({
                bottom: `${elementHeight}px`,
            });
        } else {
            setDialogPositionStyle({});
        }
    };

    const onFocusHandler = (event: React.FocusEvent<HTMLInputElement>) => {
        const element = event.currentTarget;
        if (element) {
            const elementRect = element.getBoundingClientRect();
            const spaceBelow = window.innerHeight - elementRect.bottom;
            const dialogHeight = element && element.parentElement ? element.parentElement.getElementsByClassName('timefield-dialog')[0].getBoundingClientRect().height : 0;
            if (dialogHeight > 0 && spaceBelow < dialogHeight + 5) {
                setPositionAbove(true);
                dialogStyle(elementRect.height, true);
            } else {
                setPositionAbove(false);
                dialogStyle(elementRect.height, false);
            }
        }
        setShowOptions(true);
        props.onFocus && props.onFocus(event);
    };

    const handleValueUpdate = (e: any) => {
        setInputValue(e.target.value);
        hide();
        if (onChange) onChange(e);
    };

    const hide = () => {
        setTimeout(() => {
            setShowOptions(false);
            setPositionAbove(false);
            dialogStyle(0, true);
        }, 120);
    };
    const handleTab = (e: any) => {
        if (!showOptions) return;
        // Gets all available timeslots
        const timeslots = dialogRef.current.querySelectorAll('.flos-button:not(.disabled)');

        const firstTimeslot = timeslots[0];
        const lastTimeslot = timeslots[timeslots.length - 1];

        if (!e.shiftKey && document.activeElement == lastTimeslot) {
            (firstTimeslot as HTMLElement).focus();
            return e.preventDefault();
        }
        if (e.shiftKey && document.activeElement == firstTimeslot) {
            (lastTimeslot as HTMLElement).focus();
            e.preventDefault();
        }
    };
    const handleArrowKey = (e: any, goingUp: boolean) => {
        if (!showOptions) return;
        const timeslots = dialogRef.current.querySelectorAll('.flos-button:not([disabled])');
        let currentIndex = -1;
        const firstTimeslot = timeslots[0];
        const lastTimeslot = timeslots[timeslots.length - 1];
        if (!goingUp && document.activeElement == lastTimeslot) {
            (firstTimeslot as HTMLElement).focus();
            return e.preventDefault();
        } else if (goingUp && document.activeElement == firstTimeslot) {
            (lastTimeslot as HTMLElement).focus();
            e.preventDefault();
        } else {
            timeslots.forEach((timeSlot, index) => {
                if (document.activeElement == timeSlot) {
                    currentIndex = index;
                }
            });
            if (currentIndex == -1) {
                (firstTimeslot as HTMLElement).focus();
            } else {
                if (goingUp) {
                    (timeslots[currentIndex - 1] as HTMLElement).focus();
                } else {
                    (timeslots[currentIndex + 1] as HTMLElement).focus();
                }
            }
            e.preventDefault();
        }
    };

    React.useEffect(() => {
        const keyListener = (e: any) => {
            if (e.key == 'Tab') {
                handleTab(e);
            }
            if (e.key == 'Escape') {
                hide();
            }
            if (e.key == 'Enter' && !showOptions) {
                if (inputRef.current === e.target) {
                    setShowOptions(true);
                }
            }
            if (e.key == 'ArrowUp') {
                handleArrowKey(e, true);
            }
            if (e.key == 'ArrowDown') {
                handleArrowKey(e, false);
            }
        };
        const handleClickOutside = (e: any) => {
            if (showOptions) {
                if (!inputRef.current.contains(e.target) || !dialogRef.current.contains(e.target)) {
                    hide();
                }
                return;
            }
        };
        document.body.addEventListener('keydown', keyListener);
        document.body.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.body.removeEventListener('keydown', keyListener);
            document.body.removeEventListener('mousedown', handleClickOutside);
        };
    });
    return (
        <>
            <FlosInput
                autoComplete={'off'}
                type="text"
                value={inputValue}
                onFocusCapture={onFocusHandler}
                onClick={handleInputFocus}
                onChange={callAll(onChange, handleValueUpdate)}
                {...props}
                ref={inputRef}
            />
            <div
                style={dialogPositionStyle}
                className={cx('timefield-dialog', !showOptions && 'visibilityHidden', positionAbove && 'timefield-dialog-above')}
                role="dialog"
                ref={dialogRef}
                aria-label="Vælg et tidspunkt"
                tabIndex={-1}
            >
                <div className="timefield-dialog-inner" tabIndex={-1}>
                    {options?.map((option: any) => (
                        <Button
                            className={inputValue == option.value ? 'active' : ''}
                            key={option.value}
                            value={option.value}
                            onClick={handleValueUpdate}
                            variant="secondary"
                            disabled={option.disabled}
                        >
                            {option.value}
                        </Button>
                    ))}
                    {children &&
                        childrenArray.map((child: React.ReactElement<ButtonProps>) => {
                            return React.cloneElement(child, { onClick: handleValueUpdate, className: inputValue == child.props.value ? 'active' : '' });
                        })}
                </div>
            </div>
        </>
    );
});
