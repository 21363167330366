import * as React from 'react';
import cx from 'classnames';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { useId } from '../../../utils/hooks';
import { noop } from '../../../utils';

type IconBoxProps = {
    title: string;
    iconShape: string;
    label?: string;
    selectable?: boolean;
    selected?: boolean;
    link?: string;
    variant?: 'default' | 'compact' | 'light' | 'title-outside';
} & Omit<React.HTMLAttributes<HTMLAnchorElement>, 'onSelect'>;

type InternalIconBoxProps = {
    onSelect?: (selected: { title: string; id: string; selected: boolean | undefined; iconShape: string }) => void;
};

type IconBoxHeaderProps = Omit<IconBoxProps, 'iconShape' | 'link' | 'title'>;

const IconBoxHeader = ({ label, selectable, selected }: IconBoxHeaderProps) => {
    if (!label && !selectable) {
        return null;
    }
    return (
        <div className="icon-box-header">
            {label && <p className="icon-box-label small">{label}</p>}
            {selectable && (
                <FlosIcon
                    className={selected ? 'icon-box-selected-icon has-fill is-logo-blue' : 'icon-box-selected-icon'}
                    size="medium"
                    shape={selected ? 'circle-minus' : 'circle-plus'}
                />
            )}
        </div>
    );
};

const IconBox = ({
    id,
    title,
    iconShape,
    label,
    selectable = false,
    selected = false,
    link,
    onSelect = noop,
    className,
    variant = 'default',
    ...props
}: IconBoxProps & InternalIconBoxProps) => {
    const internalId = useId(id);

    const [isSelected, setIsSelected] = React.useState<boolean | undefined>(undefined);

    const onClickHandler = (event: React.MouseEvent<HTMLAnchorElement>) => {
        if (!link) event.preventDefault();
        setIsSelected((prevIsSelected) => !prevIsSelected);
        onSelect({ title: title, id: internalId, selected: !isSelected, iconShape: iconShape });
    };

    React.useEffect(() => {
        setIsSelected(selected);
    }, [selected]);

    return (
        <div className={cx('icon-box-wrapper', variant && 'icon-box-wrapper--' + variant)}>
            <a
                id={internalId}
                className={cx('icon-box', isSelected && 'icon-box--selected', variant && 'icon-box--' + variant, className)}
                href={link}
                onClick={onClickHandler}
                style={{ flexWrap: props.children && isSelected ? 'wrap' : 'nowrap' }}
                {...props}
            >
                <IconBoxHeader label={label} selectable={selectable} selected={isSelected} />
                <FlosIcon className="icon-box-icon" shape={iconShape} size={32} />
                {title && (variant === 'default' || variant === 'light') && <h6 className="icon-box-title">{title}</h6>}
                {title && variant === 'compact' && <p className="icon-box-title">{title}</p>}
                {props.children && isSelected && (
                    <div className="icon-box-content" onClick={(event) => event.stopPropagation()}>
                        {props.children}
                    </div>
                )}
            </a>
            {title && variant === 'title-outside' && <p className="icon-box-wrapper-title">{title}</p>}
        </div>
    );
};

export type { IconBoxProps };
export type { SelectedIconBoxProps } from './icon-box-list';
export { IconBoxList } from './icon-box-list';
export { IconBox };
export default IconBox;
