import React, { useEffect, useState, useCallback, FocusEvent } from 'react';
import { TextField, TextFieldProps } from '../text-field/text-field';
import { dawaAutocomplete } from 'dawa-autocomplete2';
import { getId } from '../../../utils';

/*
 Relevant links, some is Danish only:
 https://github.com/DanmarksAdresser/dawa-autocomplete2
 https://dawadocs.dataforsyningen.dk/dok/api/autocomplete
 https://dawadocs.dataforsyningen.dk/dok/adresser
 https://danmarksadresser.dk/media/9909/samlet-vejledning-i-adressereglerne-010419-endelig.pdf
*/

type FieldEventDataProps = {
    address: string;
    addressInfo: {
        zipCode: string;
        streetName: string;
        houseNumber: string;
        postalCodeName: string;
        supCityName: string;
        floor: string;
        door: string;
    };
    status: string;
    event: string;
    kvhx: string | undefined;
    kvhxUrl: string;
};

// The event fired from the DawaComponent when the user select an address.
type DawaEvent = {
    caretpos: number; // Not important for us.
    forslagstekst: string; // Not important for us. Will contain \n between some address parts.
    tekst: string; // The text of the option chossen.
    type: string; // Not important for us. Will be set to "adresse".
    stormodtagerpostnr: boolean; // Not important for us. Will be set to false.
    data: {
        id: string; // Not important for us.
        status: number; // Not important for us.
        darstatus: number; // Not important for us.
        vejkode: string; // Not important for us.
        vejnavn: string; // Name of street
        adresseringsvejnavn: string; // Not important for us. Short version of vejnavn.
        husnr: string; // Number on street
        etage: string | null; // Floor
        dør: string | null; // Door
        supplerendebynavn: string | null; // Supplementary city name
        postnr: string; // Postal code
        postnrnavn: string; // Postal code name
        stormodtagerpostnr: string | null; // Not important for us.
        stormodtagerpostnrnavn: string | null; // Not important for us.
        kommunekode: string; // Not important for us.
        adgangsadresseid: string; // Not important for us.
        x: number; // Not important for us. GPS coordinate.
        y: number; // Not important for us. GPS coordinate.
        href: string; // URL to get more details about the address.
    };
};

interface IFieldEventFunction {
    (v: FieldEventDataProps): void;
}

type AddressFieldProps = {
    /**
     * Prop to signal that the field is submitted
     */
    isSubmitted?: boolean;
    /**
     * Function that will recieve the data from the field
     */
    fieldEvent: IFieldEventFunction;
} & TextFieldProps;

const AddressField = ({ id, label = 'Adresse', fieldEvent, errorText = 'er ugyldig', isSubmitted, isValid, ...rest }: AddressFieldProps) => {
    const [elemId] = useState(id || `addressfield-${getId()}`);
    const [isError, setIsError] = useState(false);
    const [value, setValue] = useState<DawaEvent | undefined>(undefined);
    const [kvhx, setKvhx] = useState<string | undefined>(undefined);
    const [element, setElement] = useState<string[]>([]);

    const inputRef = React.useRef() as React.MutableRefObject<HTMLInputElement>;

    function handleOnBlur(event: FocusEvent<HTMLInputElement>): void {
        // If no value selected then the field is invalid
        // If value.tekst !== event.target.value then user has changed the field without selecting, so also invalid
        if (value && value.tekst === event.target.value) {
            setIsError(false);
            fieldEvent({
                address: value.tekst,
                addressInfo: {
                    zipCode: value.data.postnr,
                    streetName: value.data.vejnavn,
                    houseNumber: value.data.husnr,
                    postalCodeName: value.data.postnrnavn,
                    supCityName: value.data.supplerendebynavn || '',
                    floor: value.data.etage || '',
                    door: value.data.dør || '',
                },
                status: 'valid',
                kvhx: kvhx,
                kvhxUrl: value.data.href,
                event: 'blur',
            });
        } else {
            setIsError(true);
            fieldEvent({
                address: '',
                addressInfo: {
                    zipCode: '',
                    streetName: '',
                    houseNumber: '',
                    postalCodeName: '',
                    supCityName: '',
                    floor: '',
                    door: '',
                },
                status: 'invalid',
                kvhx: kvhx,
                kvhxUrl: '',
                event: 'blur',
            });
        }
    }

    function handleOnFocus() {
        setIsError(false);
    }

    const dawaSelectHandler = useCallback(
        async (event: DawaEvent) => {
            setIsError(false);
            // Always use https, never http
            event.data.href = event.data.href.replace(/^http:/, 'https:');
            const details = await fetch(event.data.href).then(
                async (response) => {
                    const resp = await response.json().then(
                        (ok) => ok,
                        () => undefined
                    );
                    return resp;
                },
                () => undefined
            );
            let kvhx = undefined;
            if (details && details.kvhx) {
                kvhx = details.kvhx;
                setKvhx(kvhx);
            }
            setValue(event);
            fieldEvent({
                address: event.tekst,
                addressInfo: {
                    zipCode: event.data.postnr,
                    streetName: event.data.vejnavn,
                    houseNumber: event.data.husnr,
                    postalCodeName: event.data.postnrnavn,
                    supCityName: event.data.supplerendebynavn || '',
                    floor: event.data.etage || '',
                    door: event.data.dør || '',
                },
                status: 'valid',
                kvhx: kvhx,
                kvhxUrl: event.data.href,
                event: 'select',
            });
        },
        [fieldEvent]
    );

    useEffect(() => {
        setIsError(false);
        if (inputRef.current && !element.some((ele) => ele === elemId)) {
            setElement((prev) => [...prev, elemId]);
            dawaAutocomplete(inputRef.current, {
                adgangsadresserOnly: false,
                fuzzy: true,
                stormodtagerpostnumre: false,
                minLength: 3,
                multiline: false,
                select: dawaSelectHandler,
            });
        }
    }, [dawaSelectHandler, isValid]);

    return (
        <TextField
            isValid={isError || (isSubmitted && !value?.data.href) ? false : isValid}
            label={label}
            errorText={errorText}
            onBlur={handleOnBlur}
            onFocus={handleOnFocus}
            id={elemId}
            wrapperClassName={'autocomplete-container'}
            ref={inputRef}
            {...rest}
        />
    );
};

export { AddressField };
export type { AddressFieldProps };
export type { FieldEventDataProps };
export type { IFieldEventFunction };
