import * as React from 'react';
import { FlosInput, FlosInputProps } from '../input/flos-input';
import { validateEmail } from '../../../utils/validate';
import { useEffect, useState } from 'react';
import { noop } from '../../../utils';

type EmailInputProps = {
    isValidHandler?: (isValid?: boolean) => void;
    isValid?: boolean;
} & FlosInputProps;

/**
 * EmailInput is a wrapper around `FlosInput` component with formatting and validity check
 */
const EmailInput = React.forwardRef<HTMLInputElement, EmailInputProps>(({ value, isValid, onChange, onBlur, isValidHandler = noop, ...inputProps }, ref) => {
    const [internalValue, setInternalValue] = useState('');

    const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
        if (event.target.value === '' && (isValid || typeof isValid === 'undefined')) {
            isValidHandler(undefined);
        } else {
            setInternalValue(event.target.value);
            isValidHandler(validateEmail(internalValue));
        }
        onBlur && onBlur(event);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(event.target.value);
        onChange && onChange(event);
    };

    useEffect(() => {
        if (value) {
            setInternalValue(value);
        }
    }, [value]);

    return <FlosInput onChange={handleChange} onBlur={handleBlur} value={internalValue} {...inputProps} ref={ref} />;
});

export type { EmailInputProps };
export { EmailInput };
export default EmailInput;
