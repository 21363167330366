import classnames from 'classnames';
import * as React from 'react';

export interface ICoverageListProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLUListElement>, HTMLUListElement> {}
/**
 * `CoverageList` is the container for `CoverageListItem`.
 * It accepts all the props an `ul` element accepts
 */
export const CoverageList: React.FC<ICoverageListProps> = ({ className, ...props }) => <ul className={classnames('flos-ResponsiveCoverage-list', className)} {...props} />;

export default CoverageList;
