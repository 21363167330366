import * as React from 'react';
import cx from 'classnames';
import FlosIcon from '../../../core/icon/flos-icon';

type InfoBoxProps = {
    /** Title text */
    title: string;
    /** Icon attribute */
    iconShape: string;
    /** Top label within title and icon */
    label?: string;
    /** Text below of title */
    text?: string;
    /** Text and icon alignment */
    alignment?: 'left';
    /** Theme variants */
    theme?: 'white' | 'no-border';
    /** Actiona area like button or link */
    actionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

const InfoBox = ({ iconShape, alignment, theme, title, text, label, children, actionArea, className, ...props }: InfoBoxProps) => {
    return (
        <div className={cx('info-box', theme && 'info-box--theme-' + theme, alignment && 'info-box--align-' + alignment, className)} {...props}>
            <div className={cx('info-box-content')}>
                {iconShape && (
                    <div className="info-box-icon">
                        <FlosIcon shape={iconShape} size={64} />
                    </div>
                )}
                <div className={'info-box-text'}>
                    {label && <div className={'h5 info-box-label'}>{label}</div>}
                    {title && <div className={cx('h4', 'info-box-title', !text && 'u-spacing-stack-none')}>{title}</div>}
                    {text && <p>{text}</p>}
                </div>
            </div>
            {actionArea && <div className={'info-box-action'}>{actionArea}</div>}
        </div>
    );
};

export type { InfoBoxProps };
export { InfoBox };
export default InfoBox;
