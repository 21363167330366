import * as React from 'react';

interface IReceiptTableIconProps {
    icon: 'check' | 'cross';
}

/**
 * `ReceiptTableIcon` shows icons that is used commonly for receipt.
 * It must be inside `ReceiptTable` component for it to display correctly.
 * @deprecated Use flos-table instead
 */
export const ReceiptTableIcon: React.FC<IReceiptTableIconProps> = ({ icon }) => <span className={icon} />;
