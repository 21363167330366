import classnames from 'classnames';
import * as React from 'react';

interface ISectionProps extends React.HTMLAttributes<HTMLDivElement> {
    isHighlighted?: boolean;
    isCompact?: boolean;
    noSpaceBelow?: boolean;
    noSpaceAbove?: boolean;
    theme?: 'primary' | 'secondary';
}
/**
 * `Section` groups related items together
 */
export const Section = React.forwardRef<HTMLDivElement, ISectionProps>(
    ({ children, className, isHighlighted, isCompact, noSpaceBelow, noSpaceAbove, theme = 'primary', ...props }, ref) => {
        return (
            <section
                className={classnames(
                    'Section',
                    isHighlighted && 'Section--highlight',
                    isCompact && 'Section--compact',
                    noSpaceBelow && 'Section--no-space-below',
                    noSpaceAbove && 'Section--no-space-above',
                    theme !== 'primary' && `Section--${theme}`,
                    className
                )}
                {...props}
                ref={ref}
            >
                <div className={'container'}>{children}</div>
            </section>
        );
    }
);

export default Section;
