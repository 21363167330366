import * as React from 'react';
import classnames from 'classnames';
import { useEffect } from 'react';
import { FlosInput } from '../input/flos-input';

export type FlosCheckboxProps = {
    value?: string | number;
    label: React.ReactNode;
    errorText?: string;
    text?: React.ReactNode;
} & Omit<React.ComponentPropsWithRef<'input'>, 'label'>;
/**
 * Input is a wrapper around `<input />` html element, thus it would accepts all props a `<input />` accepts.
 *
 * You can use `ref` to access the underlying `<input />` element
 *
 * Usually this component is used in composition with other Components
 */
export const FlosCheckbox = React.forwardRef<HTMLInputElement, FlosCheckboxProps>(({ className, onChange, value, errorText, ...props }, ref) => {
    const [check, setCheck] = React.useState(!!value);

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setCheck(event.target.checked);
        onChange && onChange(event);
    };

    useEffect(() => {
        if (typeof value !== 'undefined') setCheck(!!value);
    }, [value]);

    return (
        <>
            <FlosInput
                {...props}
                id={props.id}
                disabled={props.disabled}
                onChange={(e) => handleChange(e)}
                placeholder=" "
                type="checkbox"
                checked={check}
                value={value?.toString()}
                ref={ref}
            />
            <label htmlFor={props.id} className={classnames('flos-checkbox', props.disabled ? 'is-disabled' : '')}>
                {!props.disabled && errorText && <div className="flos-checkbox--error-text">{errorText}</div>}
                <div className="flos-checkbox--label">
                    <flos-icon class="dot" shape="check2" aria-hidden="true" />
                    {props.label}
                    {props.required && '*'}
                </div>
                {props.text && <div className="flos-checkbox--text">{props.text}</div>}
            </label>
        </>
    );
});
