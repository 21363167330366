import * as React from 'react';
import { FlosField, FlosFieldProps, FlosFieldRenderInputProps } from '../flos-field';
import { TextareaProps, Textarea } from './textarea';
import { useState } from 'react';

type TextareaFieldProps = {
    /**
     * label after the remaining text count.
     */
    charactersLeftText?: string;
} & TextareaProps &
    FlosFieldProps;

/**
 * TextareaField is a composition of `FlosField` and `Textarea` components.
 * If you wish to have more control, you can compose them yourself.
 */
const TextareaField = React.forwardRef<HTMLTextAreaElement, TextareaFieldProps>(
    ({ label, wrapperClassName, isValid, errorText, maxLength, charactersLeftText = 'tegn tilbage', minRows = 4, iconShape, onValidityChange, ...textareaProps }, ref) => {
        return (
            <FlosField
                ref={ref}
                isValid={isValid}
                onValidityChange={onValidityChange}
                label={label}
                wrapperClassName={wrapperClassName}
                errorText={errorText}
                required={textareaProps.required}
                iconShape={iconShape}
                inputId={textareaProps.id}
                id={textareaProps.id}
                disabled={textareaProps.disabled}
                renderInput={({ getInputProps }) => (
                    <TextareaWithCount getInputProps={getInputProps} maxLength={maxLength} minRows={minRows} charactersLeftText={charactersLeftText} {...textareaProps} ref={ref} />
                )}
            />
        );
    }
);

const TextareaWithCount = React.forwardRef<HTMLTextAreaElement, TextareaFieldProps & FlosFieldRenderInputProps>(
    ({ getInputProps, maxLength, charactersLeftText, onChange, ...rest }, ref) => {
        const calculateCharactersLeft = (value = '') => {
            return maxLength ? maxLength - value.length : 0;
        };

        const [charactersLeft, setCharactersLeft] = useState(calculateCharactersLeft(rest.value));

        const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
            setCharactersLeft(calculateCharactersLeft(event.target.value));
            onChange && onChange(event);
        };

        return (
            <>
                <Textarea {...getInputProps({ maxLength, ...rest, ref })} onChange={handleChange} />
                {maxLength && charactersLeft >= 0 && (
                    <div className="text-right text-muted small">
                        <span>
                            {charactersLeft} {charactersLeftText}
                        </span>
                    </div>
                )}
            </>
        );
    }
);

export { TextareaField, TextareaFieldProps };
export default TextareaField;
