import * as React from 'react';
import { TooltipProps } from '../tooltip/tooltip';
import { getId } from '../../../utils';
import { TooltipIcon } from '../tooltip-icon/tooltip-icon';

export type TooltipLabeledProps = {
    label?: string;
    iconShape?: string;
} & TooltipProps;

/**
 * TooltipLabeled is a wrapper of `TooltipIcon` component supporting tooltip on icon with label.
 */
export const TooltipLabeled = React.forwardRef<HTMLDivElement, TooltipLabeledProps>(
    ({ id, label, iconShape = 'info', avoidPositions = ['left', 'top'], children, ...rest }, ref) => {
        const elementId = id || getId();
        return (
            <div className={'tooltip-labeled'} id={`tooltip-labeled-${elementId}`}>
                {label && <p className={'tooltip-labeled-text'}>{label}</p>}
                <TooltipIcon ref={ref} id={elementId} iconShape={iconShape} avoidPositions={avoidPositions} {...rest}>
                    {children}
                </TooltipIcon>
            </div>
        );
    }
);
