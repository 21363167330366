import cx from 'classnames';
import * as React from 'react';

interface IReceiptTableProps extends React.DetailedHTMLProps<React.TableHTMLAttributes<HTMLTableElement>, HTMLTableElement> {
    wrapperClassName?: string;
}

/**
 * `ReceiptTable` displays summary for a transaction.
 *
 * It accepts all props a `table` element accepts and `wrapperClassName`, which will be passed to the wrapper `div`.
 *
 * @deprecated Use flos-table instead
 */

export const ReceiptTable: React.FC<IReceiptTableProps> = ({ className, wrapperClassName, ...props }) => {
    return (
        <div className={cx('receipttable-wrapper', wrapperClassName)}>
            <table className={cx('receipttable', className)} {...props} />
        </div>
    );
};
