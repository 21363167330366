import cx from 'classnames';
import * as React from 'react';
import { useId } from '../../utils/hooks';

type ButtonProps = {
    /**
     * Will change the visual style of the button
     */
    variant?: 'primary' | 'secondary';
    /**
     * Size controls the mount of padding on the button
     */
    size?: 'large' | 'medium' | 'small';
    /**
     * Sets the min-width of the button
     */
    minWidth?: 'wide' | 'wider' | 'widest';
    /**
     * Will show animated dots after the loadingText and make the button disabled
     */
    isLoading?: boolean;
    /**
     * The content of the button when loading
     */
    loadingText?: React.ReactNode;
    /**
     * Will disable the button so the user canno interact with it
     */
    disabled?: boolean;
    /**
     * Set this to true if adding an icon inside. Icon can be before of after the button text
     */
    icon?: boolean;
} & React.ComponentPropsWithRef<'button'>;

type ButtonGroupProps = {
    stacked?: boolean;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * `Button` is a wrapper for `<button>` html element, it accepts **all** props a `<button>` element accepts in addition of props listed below.
 * You can use `ref` to access the underlying `<button>` element
 */
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    ({ className, type = 'button', variant = 'primary', size, minWidth, isLoading, loadingText, children, id, icon, disabled, ...props }, ref) => {
        const uniqueId = useId(id);

        return (
            <button
                className={cx(
                    'flos-button',
                    { [`flos-button--secondary`]: variant === 'secondary', [`flos-button--${size}`]: !!size, [`flos-button--${minWidth}`]: !!minWidth, 'flos-button--icon': icon },
                    className
                )}
                type={type}
                id={id}
                disabled={disabled}
                key={`${uniqueId}--${type}`} // See https://github.com/facebook/react/issues/8554
                {...props}
                ref={ref}
            >
                {isLoading && (
                    <>
                        {loadingText}
                        <span className="loading-dots" style={{ display: 'inline-block', marginLeft: 0 }} />
                    </>
                )}
                {!isLoading && children}
            </button>
        );
    }
);
Button.displayName = 'Button';

const ButtonGroup: React.FunctionComponent<ButtonGroupProps> = ({ stacked, children }) => {
    return <div className={cx('flos-button-group', { 'flos-button-group--stacked': stacked })}>{children}</div>;
};

export type { ButtonProps, ButtonGroupProps };
export { Button, ButtonGroup };
export default Button;
