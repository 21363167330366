import React, { useEffect } from 'react';
import cx from 'classnames';
import { FlosIcon } from '../../../core/icon/flos-icon';
import { getId } from '../../../utils';

export type MessageProps = {
    variant?: 'warning' | 'success' | 'error';
    title?: React.ReactNode;
    /** id to be used for accessibility attributes */
    id?: string;
    /**
     * Use children for the message body
     */
    children?: React.ReactNode;
    /**
     * large variant with more top and bottom padding
     */
    large?: boolean;
    /**
     * show the close Button for user to hide this message. Handle state by dismissFn callback or give an ID
     */
    dismissible?: boolean;
    /**
     * to handle close state give a `dismiss fn` OR an `id`
     */
    onDismiss?: Function;
    /**
     * to handle close state give a `dismiss fn` OR an `id`
     */
    closeId?: string;
    /**
     * to handle close state give a `dismiss fn` OR an `id`
     */
    className?: string;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>;

export const Message = ({ id, variant, dismissible, large, className, title, children, onDismiss, closeId, ...props }: MessageProps) => {
    if (!id) {
        id = getId();
    }

    /** only use session-/localStorage og cookies if we have consent */
    useEffect(() => {
        window.addEventListener('CookieInformationConsentGiven', handleConsent);
        return () => {
            window.removeEventListener('CookieInformationConsentGiven', handleConsent);
        };
    }, []);

    const [hidden, setHidden] = React.useState(false);
    const [consent, setConsent] = React.useState(false);

    if (closeId /*&& consent*/) {
        if (window.localStorage.getItem('_topdanmark_flos_message_' + closeId) === 'closed') return null;
    }

    const handleConsent = () => {
        if (window.CookieInformation.getConsentGivenFor('cookie_cat_functional')) setConsent(true);
    };

    return hidden ? null : (
        <div id={id} className={cx('message', large && 'message--large', variant && 'message--' + variant, className)} {...props}>
            {(title || dismissible) && (
                <div className={cx('message-header')}>
                    {title && <div className="message-title">{title}</div>}
                    {dismissible && (
                        <a
                            onClick={(ev) => {
                                ev.preventDefault();
                                setHidden(true);
                                onDismiss && onDismiss();
                                consent && closeId && window.localStorage.setItem('_topdanmark_flos_message_' + closeId, 'closed');
                            }}
                            href={`#${id}-close`}
                            id={`${id}-close`}
                            className="message-close-button"
                            role="button"
                        >
                            <FlosIcon shape="close" size={32} />
                        </a>
                    )}
                </div>
            )}
            {children}
        </div>
    );
};
