import * as React from 'react';

/**
 * Interface for `CheckboxGroupContext`
 */
export interface ICheckboxGroupContext {
    values: Array<string | number | null>;
    required?: boolean;
    isValid?: boolean;
    disabled?: boolean;
    errorText?: string | null;
}

/**
 * Low level API to control group of `CheckboxField`.
 *
 * Most of the time you would use `<CheckboxGroup />` which use this under the hood.
 */
export const CheckboxGroupContext = React.createContext<ICheckboxGroupContext>({
    values: [],
    required: undefined,
    isValid: undefined,
    disabled: undefined,
    errorText: undefined,
});
