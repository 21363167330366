import classnames from 'classnames';
import * as React from 'react';

enum Headings {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
}

interface IBoxListTitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
    /** the html tag that you want the header to be, default to 'h3' */
    as?: Headings;
}
/**
 * `BoxListTitle` would be contained within `BoxListTitleGroup`.
 *  Use `BoxListTitleGroup` with `title` props is you do not need to add custom class or use custom tag
 */
export const BoxListTitle: React.SFC<IBoxListTitleProps> = ({ className, as: Component = Headings.h3, ...props }) => (
    <Component className={classnames('tds-BoxList-title', className)} {...props} />
);

export default BoxListTitle;
