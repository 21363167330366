import * as React from 'react';
import TabTitle from './tab-title';
import { TabProps } from './tab';
import { cloneElement } from 'react';
import { getId } from '../../../utils';

type TabsProps = {
    children: React.ReactElement<TabProps>[];
} & React.ComponentPropsWithoutRef<'div'>;

const Tabs = ({ children, id, ...props }: TabsProps): React.ReactElement => {
    const newProps = { ...props };
    delete newProps['aria-label'];
    id = id || getId();

    const [selectedTab, setSelectedTab] = React.useState(0);

    return (
        <div className={'flos-tabs'} id={id} {...newProps}>
            <ul className={'flos-tabs-list'} id={`${id}-tablist`} role="tablist" aria-label={props['aria-label']}>
                {children.map((item: any, index: number) => (
                    <>
                        {item.props && item.props.label && (
                            <TabTitle
                                isSelected={index === selectedTab}
                                key={index}
                                label={item.props.label}
                                index={index}
                                setSelectedTab={setSelectedTab}
                                {...item.props}
                                id={`${id}-tab-${index}`}
                            />
                        )}
                    </>
                ))}
            </ul>
            {cloneElement(children[selectedTab] as any, { id: `${id}-tab-${selectedTab}` })}
        </div>
    );
};

export type { TabsProps };
export { Tabs };
export default Tabs;
