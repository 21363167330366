import classnames from 'classnames';
import * as React from 'react';

enum Tags {
    p = 'p',
    div = 'div',
    span = 'span',
}

interface IBoxListSubtitleProps extends React.DetailedHTMLProps<React.HTMLAttributes<HTMLHeadingElement>, HTMLHeadingElement> {
    /** the html tag that you want the header to be, default to 'h3' */
    as?: Tags;
}
/**
 * `BoxListSubtitle` would be contained within `BoxListTitleGroup`.
 *  Use `BoxListTitleGroup` with `subtitle` props is you do not need to add custom class or use custom tag
 */
export const BoxListSubtitle: React.SFC<IBoxListSubtitleProps> = ({ className, as: Component = Tags.p, ...props }) => (
    <Component className={classnames('tds-BoxList-subtitle', className)} {...props} />
);

export default BoxListSubtitle;
