import React, { useEffect } from 'react';
import track from 'lib-tracking';
import { get } from './services/axios.service';
import { ModalOverlay, ModalDialog, Button, Counter, noop } from 'lib-flos-ui';
import { ConfigContext } from './context/ConfigContext';
import LoggerContext from './context/LoggerContext';
import redirect from './utils/redirect';
import * as Cookies from 'es-cookie';
import createAutoLogoutRef from './utils/createAutoLogoutRef';
import createLogoutRef from './utils/createLogoutRef';

enum configEnv {
    dev = 'dev',
    integration = 'integration',
    preprod = 'pre-prod',
    prod = 'prod'
}

const envHostMapper: Record<configEnv, string> = {
    [configEnv.dev]: 'https://dnordeapensiondev2b2c.b2clogin.com/dnordeapensiondev2b2c.onmicrosoft.com',
    [configEnv.integration]: 'https://inordeapensionb2c.b2clogin.com/inordeapensionb2c.onmicrosoft.com',
    [configEnv.preprod]: 'https://ppnordeapensionb2c.b2clogin.com/ppnordeapensionb2c.onmicrosoft.com',
    [configEnv.prod]: 'https://nordeapensionb2c.b2clogin.com/nordeapensionb2c.onmicrosoft.com'
};

const logoutPath = '/B2C_1A_NLP_SIGNIN/oauth2/v2.0/logout?post_logout_redirect_uri=';
const JWT_COOKIE_NAME = 'jwt';

function App() {
    const counterRef: any = React.useRef(null);
    const config = React.useContext(ConfigContext);
    const logger = React.useContext(LoggerContext);
    const counterStart = config.counterStart || 60;
    const resourceUrl = `${config.endpointConfig.resourceBundle}/dk.session-callout/`;
    const logoutUrl = `${
        envHostMapper[config.siteConfig.env as configEnv] ?? envHostMapper[configEnv.dev]
    }${logoutPath}`;

    const [labels, setLabels]: [null, (labels: any) => void] = React.useState(null);
    const [error, setError]: [string, (error: string) => void] = React.useState('');
    const [startTime, setStartTime] = React.useState<Date>(new Date());
    const [timeLeft, setTimeLeft] = React.useState<number>(counterStart);
    const [showModal, setShowModal] = React.useState<boolean>(true);

    const calculateSeconds = (startCalcTime: Date, endCalcTime: Date) => {
        return Math.round(Math.abs(endCalcTime.valueOf() - startCalcTime.valueOf()) / 1000);
    };

    const calculateTimeLeft = React.useCallback(async () => {
        const difference = counterStart - calculateSeconds(startTime, new Date());
        setTimeLeft(difference);
    }, [counterStart, startTime]);

    const counterFormatted = (seconds: number) => {
        return `${seconds > 0 ? Math.floor(seconds / 60.0) : 0}:${('0' + (seconds % 60)).slice(-2)}`;
    };

    const reset = React.useCallback(async () => {
        clearInterval(counterRef.current);
        setTimeLeft(counterStart);
        setStartTime(new Date());
        setShowModal(true);
        track.ready(true);
    }, [counterStart]);

    const onStayInClick = () => {
        setShowModal(false);
        track.interact('stayIn clicked');
    };

    const onLogOutClick = React.useCallback(() => {
        track.interact('Logout clicked').then(() => {
            Cookies.remove(JWT_COOKIE_NAME, {
                domain: config.auth.cookieDomain || 'localhost',
                secure: config.auth.secure || false
            });
            redirect(`${logoutUrl}${createLogoutRef(config.siteConfig.originalHost, config.siteConfig.sisterHost)}`);
        });
    }, [logoutUrl, config.siteConfig.originalHost, config.siteConfig.sisterHost]);

    const autoLogOut = React.useCallback(async () => {
        track.info('autoLogout performed').then(() => {
            Cookies.remove(JWT_COOKIE_NAME, {
                domain: config.auth.cookieDomain || 'localhost',
                secure: config.auth.secure || false
            });
            redirect(
                `${logoutUrl}${createAutoLogoutRef(config.siteConfig.originalHost, config.siteConfig.sisterHost)}`
            );
        });
    }, [logoutUrl, config.siteConfig.originalHost, config.siteConfig.sisterHost]);

    const getResourceBundle = React.useCallback(async () => {
        if (!labels) {
            try {
                const response: any = await get(resourceUrl);
                setLabels(response);
            } catch (e) {
                setError((e as Error).message || 'error');
                logger.error(`Caught error in gettting Hippo labels: ` + error.toString());
            }
        }
    }, [logger, error, resourceUrl, labels]);

    useEffect(() => {
        track.ready(true);
        config.hybridAppEventBus?.subscribe('SESSION-LOGOUT-SHOW', () => {
            reset();
        });
    }, [reset, config.hybridAppEventBus]);

    useEffect(() => {
        if (timeLeft > 0 && showModal) {
            counterRef.current = setInterval(() => {
                calculateTimeLeft();
            }, 1000);
        } else if (timeLeft <= 0 && showModal) {
            showModal && autoLogOut();
        } else {
            clearInterval(counterRef.current);
        }
        return () => {
            clearInterval(counterRef.current);
        };
    }, [autoLogOut, calculateTimeLeft, showModal, startTime, timeLeft]);

    React.useEffect(() => {
        getResourceBundle();
    }, [getResourceBundle]);

    return (
        <>
            {labels && !error ? (
                <ModalOverlay
                    isOpen={showModal}
                    onDismiss={noop}
                    data-testid={'session-logout-modal'}
                    style={{ zIndex: 999999 }}
                >
                    <ModalDialog>
                        <div className="u-spacing-inset-l">
                            <div
                                className={
                                    'session-logout-app-content u-flex-box u-flex-box--space-between u-flex-box--center u-flex-box--nowrap u-spacing-stack-l'
                                }
                            >
                                <div className={'session-logout-app-text'}>
                                    <h3>{labels['callout.header']}</h3>
                                    <p className={'u-spacing-stack-none'}>{labels['callout.subject']}</p>
                                </div>
                                <Counter
                                    style={{ flexGrow: 0, flexShrink: 0 }}
                                    content={counterFormatted(timeLeft)}
                                    data-testid={'session-logout-modal-counter'}
                                />
                            </div>

                            <Button className="u-spacing-stack-xs" onClick={() => onStayInClick()}>
                                {labels['button.stay_in']}
                            </Button>
                            <Button
                                variant={'secondary'}
                                className="u-spacing-stack-xs"
                                onClick={() => onLogOutClick()}
                            >
                                {labels['button.log_out']}
                            </Button>
                        </div>
                    </ModalDialog>
                </ModalOverlay>
            ) : (
                ''
            )}
        </>
    );
}

export default App;
