import React from 'react';
import cx from 'classnames';
import { getId } from '../../../utils';

type IFlosBoxProps = {
    title?: React.ReactNode;
    /** Used if title is H3 instead of H4 - nice if you use large icons */
    isTitleLarge?: boolean;
    description?: React.ReactNode;
    /** id to be used for accessibility attributes */
    id?: string;
    /** If you need to add a icon */
    icon?: React.ReactNode;
    /** This is where you add the content on the right (in desktop view). Usually its action buttons, short messages and the like */
    actionArea?: React.ReactNode;
    /** Box is disabled = greyed out */
    isDisabled?: boolean;
    /** Ude any as children. They will show up below the title, description and actionArea */
    children?: React.ReactElement | React.ReactElement<any>[];
    /** `Content` to be shown below detailsBox. If you have eg. multiple boxes, wrap them with `React.Fragment` */
    addonContent?: React.ReactNode;
    /**Classname for parent div */
    className?: string;
} & Omit<React.ComponentPropsWithoutRef<'div'>, 'title'>;

const IfWrap = ({ children, condition, wrapper }: any) => (condition ? wrapper(children) : children);

/**
 * `FlosListBox` is a component that allows you to show information to the user, usually used in overviews and lists.
 * - For collapsible sections, use `Accordions` as children, and add you content inside those.
 */
const FlosListBox = ({ id, title, description, isDisabled, actionArea, addonContent, icon, isTitleLarge, className, children, ...rest }: IFlosBoxProps) => {
    if (!id) {
        id = getId();
    }

    return (
        <div
            className={cx(
                'flos-list-box',
                isDisabled && 'flos-list-box--disabled',
                title && 'flos-list-box--has-title',
                description && 'flos-list-box--has-description',
                actionArea && 'flos-list-box--has-action-area',
                children && 'flos-list-box--has-children',
                addonContent && 'flos-list-box--has-addon-content',
                className
            )}
            id={id}
            {...rest}
        >
            {(title || actionArea || icon) && (
                <div className={cx('flos-list-box-header')}>
                    <IfWrap condition={icon && title} wrapper={(children: any) => <div className={'flos-list-box-icon-wrap'}>{children}</div>}>
                        {icon && icon}
                        {title && <div className={cx('flos-list-box-title')}>{isTitleLarge ? <h3 id={`${id}-title`}>{title}</h3> : <h4 id={`${id}-title`}>{title}</h4>}</div>}
                    </IfWrap>
                    {actionArea && <div className={cx('flos-list-box-action-area--desktop')}>{actionArea}</div>}
                </div>
            )}
            {description && (
                <div className={'flos-list-box-description'} id={`${id}-description`}>
                    {description}
                </div>
            )}
            {children && <div className={cx('flos-list-box-children')}>{children}</div>}
            {actionArea && <div className={cx('flos-list-box-action-area--mobile')}>{actionArea}</div>}
            {addonContent && <div className={cx('flos-list-box-addon-content')}>{addonContent}</div>}
        </div>
    );
};

export type { IFlosBoxProps };
export { FlosListBox };
export default FlosListBox;
