import React from 'react';
import Section from '../../../core/layout/section/section';
import cx from 'classnames';
import { SelectField } from '../../../core/forms/select/select-field/select-field';
import { useId } from '../../../utils/hooks';

type FlosLocalNavItemProps = {
    value: string;
    label: string;
};

type FlosLocalNavProps = {
    /** The main target to observe for changes */
    observerSelector?: string;
    /** The targets (normally sections, page-headers, heros etc.) having an id, which should be listed a navItems */
    navItemsSelector?: string;
    /** Selector for the labels of the  navItems if the data-local-nav-text attribute not set on the navItemsSelector */
    labelSelector?: string;
    /** Min number of navItems to be present before rendering the local-nav */
    minNavItemsToRender?: number;
} & React.ComponentPropsWithoutRef<'div'>;

const FlosLocalNav = ({
    observerSelector = 'main',
    navItemsSelector = 'main .flos-pageheader[id]:not([id=""]), main .Hero[id]:not([id=""]), main .Section[id]:not([id=""])',
    labelSelector = '.Section-header, h1.flos-pageheader-title, h1.Hero-box-header',
    minNavItemsToRender = 2,
    ...props
}: FlosLocalNavProps) => {
    const id = useId(props.id);

    const [navItems, setNavItems] = React.useState<FlosLocalNavItemProps[]>([]);
    const [activeId, setActiveId] = React.useState<string>('');

    const getSectionHeaders = (initial: boolean) => {
        let navItemsCollected: FlosLocalNavItemProps[] = [];
        const sectionsDom = document.querySelectorAll(navItemsSelector);

        sectionsDom &&
            sectionsDom.forEach((section: HTMLElement) => {
                if ((section?.querySelector(labelSelector)?.textContent !== '' || section?.dataset['localNavText'] !== '') && section.id !== id) {
                    const label = section?.dataset['localNavText'] ? section.dataset['localNavText'] : section?.querySelector(labelSelector)?.textContent;
                    if (!navItemsCollected.find((item: FlosLocalNavItemProps) => item.value === section.id)) {
                        label &&
                            navItemsCollected.push({
                                value: section.id,
                                label: label,
                            });
                    }
                }
            });

        setNavItems(navItemsCollected);

        if (initial) {
            if (document.getElementsByClassName(observerSelector)[0]) {
                domObserver.observe(document.getElementsByClassName(observerSelector)[0], { childList: true, subtree: true, attributes: true });
            } else {
                console.log(`No node available to observe on local-nav with selector: ${observerSelector}`);
            }
        }

        if (activeObserver) {
            activeObserver.disconnect();
        }

        sectionsDom &&
            sectionsDom.forEach((target) => {
                activeObserver.observe(target);
            });
    };

    const updateHeaders = () => {
        getSectionHeaders(false);
    };

    const updateActive = (entries: IntersectionObserverEntry[]) => {
        entries.forEach((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting && entry.target.id !== activeId && entry.target.id !== id) {
                setActiveId(entry.target.id);
            }
        });
    };

    const domObserver = new MutationObserver(updateHeaders);

    const activeObserver = new IntersectionObserver(updateActive, {
        root: null,
        rootMargin: '0% 0% -80% 0%',
        threshold: 0,
    });

    React.useEffect(() => {
        getSectionHeaders(true);
        return () => {
            domObserver.disconnect();
            activeObserver.disconnect();
        };
    }, []);

    return (
        <React.StrictMode>
            {navItems.length >= minNavItemsToRender && (
                <Section className={'flos-local-nav'} {...props} id={id}>
                    <div className={'flos-local-nav-container'} id={`${id}-flos-local-nav-container`}>
                        <div className={'flos-local-nav-container-desktop'} id={`${id}-flos-local-nav-desktop`}>
                            {navItems.map((item: any, index: number) => (
                                <a
                                    id={`${id}-flos-local-nav-desktop-link-${item.value}`}
                                    className={cx('flos-button flos-button--outline', (activeId === item.value || (!activeId && index === 0)) && 'active')}
                                    key={index}
                                    href="#"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        window.location.replace(`#${item.value}`);
                                    }}
                                    role={'link'}
                                >
                                    {item.label}
                                </a>
                            ))}
                        </div>
                        <div className={'flos-local-nav-container-mobile'} id={`${id}-flos-local-nav-mobile`}>
                            <SelectField
                                id={`${id}-flos-local-nav-mobile-select`}
                                options={navItems}
                                value={activeId || (navItems && navItems[0].value)}
                                onChange={(e) => {
                                    e.preventDefault();
                                    window.location.replace(`#${e.target.value}`);
                                }}
                            />
                        </div>
                    </div>
                </Section>
            )}
        </React.StrictMode>
    );
};

export type { FlosLocalNavProps, FlosLocalNavItemProps };
export { FlosLocalNav };
