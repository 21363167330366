import { TooltipPopup, TooltipProps as OriTooltipProps, Position } from '@reach/tooltip';
import cx from 'classnames';
import * as React from 'react';
import { includes } from '../../../utils/array';
import { callAll } from '../../../utils/fp';
import { omit } from '../../../utils/object';
import { useTooltip } from '../../../utils/reach-tooltip';
import { isNil } from '../../../utils/typeguard';

type PositionParams = Parameters<Position>;

type TooltipPosition = 'right' | 'top' | 'bottom' | 'left';

/**
 * Calculate the position of tooltip based on trigger and tooltip dimensions.
 *
 * By default, the preference of the position is left, right, top, bottom.
 *
 */
const calculateTooltipPosition = (
    triggerRect: PositionParams[0],
    tooltipRect: PositionParams[1],
    avoidPositions: TooltipPosition[],
    hasIcon: boolean | undefined
): [DOMRect, TooltipPosition] => {
    const TOOLTIP_OFFSET = hasIcon ? -16 : 8;

    if (isNil(triggerRect)) {
        return [
            {
                left: 0,
                top: hasIcon ? 16 : 0,
            } as any,
            'bottom',
        ];
    }

    const tooltipRectWidth = tooltipRect ? tooltipRect.width : 0;

    if (triggerRect.left - tooltipRectWidth - TOOLTIP_OFFSET > 0 && !includes(avoidPositions, 'left')) {
        return [
            {
                left: triggerRect.left - tooltipRectWidth - TOOLTIP_OFFSET + window.pageXOffset,
                top: triggerRect.top + window.pageYOffset + (hasIcon ? 16 : 0),
            } as any,
            'left',
        ];
    }

    const right = triggerRect.right + tooltipRectWidth / 2;
    const maxRight = window.innerWidth - tooltipRectWidth / 2;

    // give priority to right
    if (right < maxRight && !includes(avoidPositions, 'right')) {
        return [
            {
                left: triggerRect.right + TOOLTIP_OFFSET + window.pageXOffset,
                top: triggerRect.top + window.pageYOffset + (hasIcon ? 16 : 0),
            } as any,
            'right',
        ];
    } else {
        const triggerCenter = triggerRect.left + triggerRect.width / 2;

        const calculatedLeft = triggerCenter - tooltipRectWidth / 2;
        const maxLeft = window.innerWidth - tooltipRectWidth - 2;

        const top = triggerRect.top - (tooltipRect ? tooltipRect.height : 0) - TOOLTIP_OFFSET + window.pageYOffset;
        const finalTop = top > window.pageYOffset ? top : triggerRect.bottom + TOOLTIP_OFFSET + window.pageYOffset;

        return [
            {
                left: Math.min(Math.max(2, calculatedLeft), maxLeft) + window.pageXOffset + (hasIcon ? -32 : 0),
                top: finalTop,
                width: Math.min(window.innerWidth - 6, tooltipRectWidth),
            } as any,
            top > window.pageYOffset ? 'bottom' : 'top',
        ];
    }
};

export type TooltipProps = {
    /**
     * Restrict the tooltip only show/hide during focus/blur
     */
    showWhenFocus?: boolean;
    /**
     * Force the tooltip not not shown
     */
    forceHide?: boolean;
    /**
     * Positions that you want to avoid
     */
    avoidPositions?: Array<'left' | 'right' | 'top'>;
    /**
     * Position for icon tooltip
     */
    hasIcon?: boolean;
} & OriTooltipProps &
    JSX.IntrinsicElements['div'];

/**
 * `Tooltip` is a wrapper over [`@reach/tooltip`](https://ui.reach.tech/tooltip/) by applying className in Topdanmark site and additional options.
 *
 * There are few assumptions when using this component:
 * - you only pass a single react element as the children
 * - the child react element must be either a plain html element, e.g. `<Button>`, `<div>`, or a React element that will forwardRef to child, e.g. `Input` and `TextField`.
 * The `ref` will be the trigger object which the tooltip will anchor to.
 *
 * By default, the interactions flow goes like this:
 *  1. when your mouse hover in, tooltip will be shown
 *  2. tooltip will disappear when either you click on the element or move your mouse out
 *
 * You can customize the interfactions flow to show when focus, hide when blur by passing down `showWhenFocus` props.
 *
 */
export const Tooltip = ({ hasIcon, children, label, ariaLabel, className, showWhenFocus, forceHide, style, avoidPositions = [], ...tooltipProps }: TooltipProps) => {
    const [trigger, tooltip] = useTooltip();
    const { isVisible, triggerRect } = tooltip;
    const [tooltipDirection, setTooltipRect] = React.useState<null | TooltipPosition>(null);
    const tooltipRef = React.useRef<HTMLDivElement | null>(null);

    React.useEffect(() => {
        if (tooltipRef.current) {
            const tooltipDomRect = tooltipRef.current.getBoundingClientRect();
            const [, direction] = calculateTooltipPosition(triggerRect, tooltipDomRect, avoidPositions, hasIcon);
            if (!tooltipDirection || direction !== tooltipDirection) {
                setTooltipRect(direction);
            }
        }
    }, [triggerRect, avoidPositions, tooltipDirection]);

    const triggerObj = showWhenFocus ? omit(trigger, ['onMouseDown', 'onMouseLeave', 'onMouseEnter', 'onMouseMove', 'onKeyDown']) : trigger;

    let child: React.ReactNode = null;

    if (React.isValidElement(children) && React.Children.only(children)) {
        child = React.cloneElement(children as React.ReactElement, {
            ...triggerObj,
            onBlur: callAll(triggerObj.onBlur, children.props.onBlur),
            onFocus: callAll(triggerObj.onFocus, children.props.onFocus),
            onKeyDown: callAll((triggerObj as any).onKeyDown, children.props.onKeyDown),
            onMouseDown: callAll((triggerObj as any).onMouseDown, children.props.onMouseDown),
            onMouseEnter: callAll((triggerObj as any).onMouseEnter, children.props.onMouseEnter),
            onMouseLeave: callAll((triggerObj as any).onMouseLeave, children.props.onMouseLeave),
            onMouseMove: callAll((triggerObj as any).onMouseMove, children.props.onMouseMove),
        });
    }

    return (
        <>
            {child}
            <TooltipPopup
                {...tooltipProps}
                {...tooltip}
                ref={tooltipRef}
                className={cx('dialog', tooltipDirection && `pos-${tooltipDirection}`, hasIcon && 'dialog-icon', className)}
                label={<div className="dialog-content">{label}</div>}
                ariaLabel={ariaLabel}
                style={{
                    ...style,
                    display: isVisible && !forceHide ? 'block' : 'none',
                }}
                position={(triggerDomRect, tooltipDomRect) => {
                    const [result] = calculateTooltipPosition(triggerDomRect, tooltipDomRect, avoidPositions, hasIcon);
                    return result;
                }}
            />
        </>
    );
};
