import * as React from 'react';
import { useEffect, useState } from 'react';
import cx from 'classnames';

type HTMLInputProps = React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

export interface IRangeInputProps<ValueType> extends HTMLInputProps {
    /** Array of strings or numbers */
    options: Array<number | string>;
    /** callback that will be invoked with the new value when value is changed. */
    onChangeValue?: (value: ValueType) => void;
    /** Show a header above the slider, containing the selected value. */
    showValue?: boolean;
    /** subtitle to value */
    subTitle?: string;
    /** validation status of the slider */
    isValid?: boolean;
    /** Error text for the field that will be displayed above the slider to the right if the field is invalid */
    errorText?: string;
}

type RangeInputComponent<T = any> = React.ForwardRefExoticComponent<IRangeInputProps<T>>;

/**
 * `RangeInput` allows you to put an arbitrary List of options and renders them along a range.
 *
 */
export const RangeInput: RangeInputComponent = React.forwardRef<HTMLInputElement, IRangeInputProps<any>>(function RangeInputImpl(
    { value, isValid, subTitle, errorText = 'Vælg et niveau', options = [], onChangeValue, showValue, disabled, onChange, ...props },
    ref
) {
    const min = options[0];
    const max = options[options.length - 1];
    const getValueIndex = (val: any) => options.indexOf(val);

    const [internalValue, setInternalValue] = useState<any>(value ? getValueIndex(value) : 0);

    const coloredThumb = (val: any, min: any, max: any) => ((val - min) * 100) / (max - min);

    const onChangeHandler = (ev: React.ChangeEvent<HTMLInputElement>) => {
        setInternalValue(ev.target.value);
        const newValue = options[ev.target.value];
        let target = ev.target;
        target.style.backgroundSize = coloredThumb(target.value, target.min, target.max) + '% 100%';

        onChangeValue && onChangeValue(newValue);
        onChange && onChange(ev);
    };

    useEffect(() => {
        setInternalValue(value ? getValueIndex(value) : 0);
    }, [value]);

    return (
        <div className={'flos-range-input'}>
            {showValue && (
                <div className={cx('flos-range-input-value', disabled && 'flos-range-input-value--disabled')}>
                    <h2>{options[internalValue]}</h2>
                    {subTitle && <p>{subTitle}</p>}
                </div>
            )}
            <div className={'flos-range-input-slider'}>
                <input
                    disabled={disabled}
                    style={{ backgroundSize: `${coloredThumb(internalValue, options.indexOf(min), options.indexOf(max))}% 100%` }}
                    type="range"
                    aria-valuemin={min as number}
                    aria-valuemax={max as number}
                    aria-valuenow={options[internalValue] as number}
                    value={internalValue}
                    min={0}
                    max={options.length - 1}
                    step={1}
                    ref={ref}
                    onChange={onChangeHandler}
                    {...props}
                />
                {isValid === false && disabled !== true && <p className={'flos-range-input-slider-errortext small'}>{errorText}</p>}
            </div>
        </div>
    );
});
