import React from 'react';
import { IFlowMenuItemProps } from './flow-menu-item';

export interface IFlowMenuProps {
    /** Only use <FlowMenuItem>'s as children. They will show up as clickable boxes */
    children?: React.ReactElement<IFlowMenuItemProps> | React.ReactElement<IFlowMenuItemProps>[];
}

/**
 * `FlowMenu` is a component that allows you to make a flows like subscription flows.
 */

export const FlowMenu = ({ ...rest }: IFlowMenuProps) => {
    return <div className="flow-menu">{rest.children}</div>;
};
