import * as React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { EmailInput, EmailInputProps } from './email-input';
import { useEffect, useState } from 'react';

type EmailFieldProps = EmailInputProps & FlosFieldProps;

/**
 * EmailField is a composition of `FlosField` and `EmailInput` component.
 * If you wish to have more control, you can compose them yourself.
 */
const EmailField = React.forwardRef<HTMLInputElement, EmailFieldProps>(function EmailFieldComp(
    { label = 'E-mail', iconShape, isValid, errorText = 'er ikke korrekt udfyldt', wrapperClassName, editable, onValidityChange, ...inputProps },
    ref
) {
    const [state, setState] = useState<boolean | undefined>(undefined);
    const isValidHandler = (isEmailValid?: boolean) => setState(isValid ? isValid : isEmailValid);

    useEffect(() => {
        setState(isValid);
    }, [isValid]);

    return (
        <FlosField
            ref={ref}
            isValid={state}
            onValidityChange={onValidityChange}
            editable={editable}
            label={label}
            iconShape={iconShape}
            errorText={errorText}
            required={inputProps.required}
            id={inputProps.id}
            disabled={inputProps.disabled}
            wrapperClassName={wrapperClassName}
            renderInput={({ getInputProps }) => <EmailInput {...getInputProps({ ...inputProps, ref })} isValidHandler={isValidHandler} isValid={isValid} />}
        />
    );
});

export type { EmailFieldProps };
export { EmailField };
export default EmailField;
