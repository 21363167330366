import React, { useState } from 'react';
import cx from 'classnames';
import { getId } from '../../../utils';
import { ListBoxContext, IListBoxContext } from './list-box-context';
import { IListBoxPanelProps } from './list-box-panel';
import { ListBoxPanelLink } from './list-box-panel-link';

export interface IListBoxProps {
    title?: React.ReactNode;
    description?: React.ReactNode;
    /** id to be used for accessibility attributes */
    id?: string;
    /** background-color and color of the `ListBox` */
    boxColorClass?: string;
    /** This is where you add the content on the right (in desktop view). Usually its action buttons, short messages and the like */
    actionArea?: React.ReactNode;
    /** is the size of the icon large */
    iconLarge?: boolean;
    /** shape name of the icon in @flos/icons  */
    iconShape?: string;
    /** If you need to add a custom icon */
    icon?: React.ReactNode;
    /** color and-or fill of the icon */
    iconColorClass?: 'is-red' | 'is-green' | 'is-red has-fill' | 'is-green has-fill';
    /** id for the section that is expanded initially. */
    defaultExpandedSectionId?: string;
    /** `Content` to be shown below detailsBox. If you have eg. multiple boxes, wrap them with `React.Fragment` */
    addonContent?: React.ReactNode;
    /** if the box should be disbaled */
    isDisabled?: boolean;
    /** Decreases top and bottom padding in the box - usefull for screens with a LOT of boxes!*/
    compact?: boolean;
    /** Hides the dashed border in some of the parts of the ListBox */
    noBorder?: boolean;
    /** Only use <ListBoxPanel>'s as children. They will show up as expanable sections in the ListBox */
    children?: React.ReactElement<IListBoxPanelProps> | React.ReactElement<IListBoxPanelProps>[];
    /**Classname for parent div */
    className?: string;
}

/**
 * `ListBox` is a component that allows you to show information to the user, usually used in overviews and lists.
 * - For collapsible sections, use `ListBoxPanel` as children, and add you content inside those.
 * @deprecated Use FlosListBox
 */
export const ListBox = ({
    id,
    defaultExpandedSectionId,
    iconShape,
    iconLarge,
    iconColorClass,
    title,
    description,
    compact = false,
    noBorder = false,
    boxColorClass,
    isDisabled,
    actionArea,
    addonContent,
    icon,
    ...rest
}: IListBoxProps) => {
    if (!id) {
        id = getId();
    }

    const [state, setState] = useState<IListBoxContext>({
        expandedSectionId: defaultExpandedSectionId || null,
    });

    const expandSection = (sectionId: string) => {
        setState({
            expandedSectionId: sectionId,
        });
    };

    return (
        <ListBoxContext.Provider value={state}>
            <div
                id={id}
                className={cx(
                    'list-box',
                    noBorder && 'list-box--no-border',
                    state.expandedSectionId !== null && 'expanded',
                    rest.children && 'children-exist',
                    rest.className && rest.className
                )}
                {...rest}
            >
                <div
                    className={cx(
                        'Box',
                        compact && 'Box--compact',
                        (iconShape || icon) && 'Box--has-icon',
                        iconLarge && 'Box--has-icon--large',
                        boxColorClass && boxColorClass,
                        isDisabled && 'Box--disabled'
                    )}
                >
                    {!iconShape && icon && icon}
                    {iconShape && <flos-icon size={iconLarge ? 48 : 24} shape={iconShape} class={cx('icon', iconColorClass && iconColorClass)} />}
                    <div>
                        {title && <h4 id={`${id}-title`}>{title}</h4>}
                        {description && <p id={`${id}-description`}>{description}</p>}
                        {rest.children &&
                            React.Children.map(rest.children, (child: React.ReactElement<IListBoxPanelProps>) => (
                                <ListBoxPanelLink callBack={expandSection} sectionId={child.props.id} label={child.props.label} expandedLabel={child.props.expandedLabel} />
                            ))}
                    </div>
                </div>
                {rest.children && <div className="Box Box-remove-padding hidden-md hidden-lg">{rest.children}</div>}
                {actionArea && <div className={cx('Box', compact && 'Box--compact', boxColorClass && boxColorClass)}>{actionArea}</div>}
            </div>
            {rest.children && <div className="hidden-sm hidden-xs">{rest.children}</div>}

            {addonContent && (
                <div className={cx('Box--wrapper', noBorder && 'Box--wrapper-no-border', boxColorClass && boxColorClass, rest.children && 'children-exist')}>{addonContent}</div>
            )}
        </ListBoxContext.Provider>
    );
};
