import { includes } from './array';
/**
 * Commonly used regexes
 */
export const regexSets = {
    allDigits: /^\d*$/,
    danishNumber: /^([2-8]\d{7}|9[1-9]\d{6})$/,
    // General Email Regex (RFC 5322 Official Standard)
    email: /^(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])$/i,
};

/**
 * validates if the user-provided phone number is valid
 *
 * By default the phone number must follow Danish phone number format, set `isInternational` to `true` if you allow phone number other than Danish phone number.
 *
 * @param phoneNumber phone number that user has provided
 * @param isInternational state if the phone number could be number other than Danish
 */
export const validatePhone = (phoneNumber: string | number | undefined, isInternational = false) =>
    !!phoneNumber && (isInternational || regexSets.danishNumber.test(phoneNumber.toString()));

/**
 * validates if the email format is correct and can be handled by Topdanmark applications
 * @param email email that is provided
 */
export const validateEmail = (email: string | undefined | null): email is string =>
    !!email &&
    email.toString().length <= 47 && // email max characters in mainframe system (GTI)
    regexSets.email.test(email);

/**
 * validates if the CPR number format is correct (string with 10 chars and all digits)
 * @param cprNumber CPR number that is provided
 */
const validateCprFormat = (cprNumber: string | undefined | null): cprNumber is string => !!cprNumber && cprNumber.length === 10 && regexSets.allDigits.test(cprNumber);

const extractCprNumberInfo = (cprNumber: string) => ({
    birthday: cprNumber.substr(0, 6),
    century: +cprNumber.substr(6, 1),
    controldigit: +cprNumber.substr(9, 1),
    day: +cprNumber.substr(0, 2),
    month: +cprNumber.substr(2, 2),
    serialno: +cprNumber.substr(7, 2),
    year: cprNumber.substr(4, 2),
});

const isThisCentury = (year: number, century: number) => {
    switch (century) {
        case 0:
        case 1:
        case 2:
        case 3:
            return false;
        case 4:
        case 9:
            return year <= 36;
        case 5:
        case 6:
        case 7:
        case 8:
            return year <= 57;
    }
    return true;
};

/**
 * These birthdays are exempted for cpr modulus 11 check
 */
const cprBirthdayWhiteList = [
    '010160',
    '010164',
    '010165',
    '010166',
    '010169',
    '010170',
    '010180',
    '010182',
    '010184',
    '010185',
    '010186',
    '010187',
    '010188',
    '010189',
    '010190',
    '010192',
];
/**
 * The date where people born after this are exempted for cpr modulus 11 check
 */
const cprCheckBirthdateCutoff = new Date(2007, 10, 1);

const modulus11Check = (cpr: string) => {
    let modulusCheck = 4 * +cpr[0];
    modulusCheck += 3 * +cpr[1];
    modulusCheck += 2 * +cpr[2];
    modulusCheck += 7 * +cpr[3];
    modulusCheck += 6 * +cpr[4];
    modulusCheck += 5 * +cpr[5];
    modulusCheck += 4 * +cpr[6];
    modulusCheck += 3 * +cpr[7];
    modulusCheck += 2 * +cpr[8];
    modulusCheck += +cpr[9];
    return modulusCheck % 11 === 0;
};

const validateCprMod11Check = (cprNumber: string) => {
    const cprInfo = extractCprNumberInfo(cprNumber);

    if (includes(cprBirthdayWhiteList, cprInfo.birthday)) {
        return true;
    }

    const century = isThisCentury(+cprInfo.year, cprInfo.century) ? '20' : '19';
    const year = +`${century}${cprInfo.year}`;

    if (new Date(year, cprInfo.month, cprInfo.day) >= cprCheckBirthdateCutoff) {
        return true;
    }

    return modulus11Check(cprNumber);
};

/**
 * Validates if the CPR number is valid:
 *
 * - 10 characters
 * - all digits
 * - the number pass the [modulus 11 check](https://en.wikipedia.org/wiki/Personal_identification_number_(Denmark)#New_development_in_2007) (with exceptions)
 * @param cprNumber
 */
export const validateCpr = (cprNumber: string | undefined | null) => validateCprFormat(cprNumber) && validateCprMod11Check(cprNumber);

const getAge = (birthDate: Date, currentTime: number) => {
    const ageDiffInMs = currentTime - birthDate.getTime();
    const ageDate = new Date(ageDiffInMs);
    return Math.abs(ageDate.getUTCFullYear() - 1970);
};

interface IValidateCprOptions {
    /** minimum allowed age  */
    minAge?: number;
    /** maximum allowed age */
    maxAge?: number;
    /** current time that age should be computed against, default to `Date.now()` */
    currentTime?: number;
}

/**
 * validate the age of the person is within the allowed range based on cpr number
 * @param cprNumber cpr number of the person
 * @param options options of the validations
 *
 * The options parameter is an object with three possible properties (all optional):
 * - `minAge`: number - minimum allowed age
 * - `maxAge`: number - maximum allowed age
 * - `currentTime`: number - current time that age should be computed against, default to `Date.now()`
 *
 * This function returns `null` if the age fits within the specified range.
 * @param cprNumber
 * @param options
 */
export const validateCprAge = (cprNumber: string, options: IValidateCprOptions) => {
    const { minAge, maxAge, currentTime = Date.now() } = options;
    const cprInfo = extractCprNumberInfo(cprNumber);

    const century = isThisCentury(+cprInfo.year, cprInfo.century) ? '20' : '19';
    const year = +`${century}${cprInfo.year}`;
    const birthDate = new Date(year, cprInfo.month, cprInfo.day);

    const age = getAge(birthDate, currentTime);

    if (minAge && age < minAge) {
        return 'TOO_YOUNG';
    }

    if (maxAge && age > maxAge) {
        return 'TOO_OLD';
    }

    return null;
};

/**
 * validate the account number is valid (all digits + correct length)
 * @param accountNumber
 * @param minLength minimum length of account number (default to 4)
 */
export const validateAccountNumber = (accountNumber: string | number, minLength = 4) => {
    if (!accountNumber) {
        return false;
    }

    const value = accountNumber.toString();

    return value.length >= minLength && value.length <= 10 && regexSets.allDigits.test(value);
};

/**
 * validate the bank number is valid (4 digits)
 * @param bankNumber
 */
export const validateBankNumber = (bankNumber: string | number) => {
    if (!bankNumber) {
        return false;
    }

    const value = bankNumber.toString();

    return value.length === 4 && regexSets.allDigits.test(value);
};
