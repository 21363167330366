import * as React from 'react';
import { FlosField, FlosFieldProps } from '../flos-field';
import { TimeInput, TimeInputProps } from './time-input';
import cx from 'classnames';

export type TimeFieldProps = TimeInputProps & FlosFieldProps;

export const TimeField = React.forwardRef<HTMLInputElement, TimeFieldProps>(function TimeFieldComp(
    { label, wrapperClassName, isValid, errorText = 'er ikke korrekt udfyldt', iconShape = 'clock', onValidityChange, ...inputProps },
    ref
) {
    return (
        <FlosField
            ref={ref}
            id={inputProps.id}
            isValid={isValid}
            onValidityChange={onValidityChange}
            label={label}
            wrapperClassName={cx('timefield-wrapper', wrapperClassName && wrapperClassName)}
            iconShape={iconShape}
            errorText={errorText}
            required={inputProps.required}
            disabled={inputProps.disabled}
            hasDropdown
            renderInput={({ getInputProps }) => <TimeInput {...getInputProps({ ...(inputProps as any), ref })} />}
        />
    );
});
