import cx from 'classnames';
import * as React from 'react';
import { useId } from '../../../utils/hooks';

type PageHeaderProps = {
    /** Options to defined content alignments */
    align?: 'left' | 'center' | 'right';
    /** Used to add element like navigation link (back link) or a breadcrumb before header */
    headArea?: React.ReactNode;
    /** Used to render image on right of body content*/
    imageUrl?: string;
    /** Label text/element that display within `<h5>` header 5 */
    label?: React.ReactNode;
    /** Secondary title/element that display within `<h2>` header 2 */
    subtitle?: React.ReactNode;
    /** Options that will affect the component color scheme*/
    theme?: 'primary' | 'secondary' | 'info' | 'danger' | 'success';
    /** Main title/element that display within `<h1>` header 1 */
    title: React.ReactNode;
} & React.ComponentPropsWithoutRef<'div'>;

/**
 * Header component wrap the children in a banner-like container
 */
const PageHeader: React.FunctionComponent<PageHeaderProps> = ({ align, children, id, headArea, title, subtitle, label, theme = 'primary', imageUrl, className, ...props }) => {
    const componentId = useId(id);
    const componentClassName = 'flos-pageheader';

    return (
        <div id={componentId} {...props} className={cx(componentClassName, `${componentClassName}--${theme}`, { [`${componentClassName}--${align}-aligned`]: !!align }, className)}>
            <div className={cx('container', `${componentClassName}-row`, { [`${componentClassName}-row--with-image`]: !!imageUrl })}>
                {imageUrl && (
                    <div className={cx(`${componentClassName}-image`)}>
                        <img className={`${componentClassName}-image-content`} src={imageUrl} alt="" width="400" />
                    </div>
                )}
                <div className={cx(`${componentClassName}-content-col`, { [`${componentClassName}-content-col--with-image`]: !!imageUrl })}>
                    {headArea && (
                        <div
                            id={`${componentId}-${componentClassName}-nav`}
                            className={cx(`${componentClassName}-head-area`, { [`${componentClassName}-head-area--${align}-aligned`]: !!align })}
                        >
                            {headArea}
                        </div>
                    )}
                    {label && (
                        <h5 id={`${componentId}-${componentClassName}-label`} className={cx(`${componentClassName}-label`, `${componentClassName}-label--${theme}`)}>
                            {label}
                        </h5>
                    )}
                    <h1 id={`${componentId}-${componentClassName}-title`} className={cx(`${componentClassName}-title`, `${componentClassName}-title--${theme}`)}>
                        {title}
                    </h1>
                    {subtitle && (
                        <h3 id={`${componentId}-${componentClassName}-subtitle`} className={cx(`${componentClassName}-subtitle`, `${componentClassName}-subtitle--${theme}`)}>
                            {subtitle}
                        </h3>
                    )}
                    {children && (
                        <div
                            id={`${componentId}-${componentClassName}-body`}
                            className={cx(`${componentClassName}-body`, { [`${componentClassName}-body--${align}-aligned`]: !!align })}
                        >
                            {children}
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};
export type { PageHeaderProps };
export { PageHeader };
export default PageHeader;
