import cx from 'classnames';
import * as React from 'react';

type ColCount = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;

type ColProps = JSX.IntrinsicElements['div'] & {
    xs?: ColCount;
    sm?: ColCount;
    md?: ColCount;
    lg?: ColCount;
    offsetXs?: ColCount;
    offsetSm?: ColCount;
    offsetMd?: ColCount;
    offsetLg?: ColCount;
};

/**
 * Bootstrap column
 * @deprecated
 */
export const Col = ({ className, xs, sm, md, lg, offsetXs, offsetSm, offsetMd, offsetLg, ...props }: ColProps) => (
    <div
        {...props}
        className={cx(
            xs && `col-xs-${xs}`,
            sm && `col-sm-${sm}`,
            md && `col-md-${md}`,
            lg && `col-lg-${lg}`,
            offsetXs && `col-xs-offset-${offsetXs}`,
            offsetSm && `col-sm-offset-${offsetSm}`,
            offsetMd && `col-md-offset-${offsetMd}`,
            offsetLg && `col-lg-offset-${offsetLg}`,
            className
        )}
    />
);
