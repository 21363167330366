import { ConfigContext, IConfigProps } from './context/ConfigContext';
import { C9Logger } from 'lib-js-log-client';

import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import LoggerContext from './context/LoggerContext';

let context = (window as any) || {};

context.SessionLogoutApp = (function () {
    function boostrapApplication(config: IConfigProps, boostrapElement: any) {
        const logger = new C9Logger(config.endpointConfig.logger, config.appName, config.logLevel);
        logger.debug(`Starting up application ${logger.appName}`);
        ReactDOM.render(
            <ConfigContext.Provider value={config}>
                <LoggerContext.Provider value={logger}>
                    <App />
                </LoggerContext.Provider>
            </ConfigContext.Provider>,
            boostrapElement
        );
    }
    return boostrapApplication;
})();
