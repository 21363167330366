import * as React from 'react';
import cx from 'classnames';

type HeroBannerProps = {
    imageUrl?: string;
    theme?: 'primary' | 'secondary' | 'transparent';
    label?: string;
    title: string;
    subtitle?: string;
    /**
     * Children to render below subtitle
     */
    children?: React.ReactNode;
    /**
     * Campaign Splash area
     */
    splashArea?: React.ReactNode;
    /**
     * Action buttons or links for the Hero content area
     */
    actionArea?: React.ReactNode;
    /**
     * 12 col grid area below content to add any custom stuff - Only applicable when transparent theme
     */
    bottomActionArea?: React.ReactNode;
} & React.ComponentPropsWithoutRef<'article'>;

const HeroBanner = ({ imageUrl, theme, title, subtitle, label, splashArea, children, actionArea, bottomActionArea, className, ...props }: HeroBannerProps) => {
    return (
        <article className={cx('flos-hero', theme && 'flos-hero--' + theme, splashArea && theme !== 'transparent' && 'has-splash', className)} {...props}>
            {imageUrl && theme === 'transparent' && (
                <picture>
                    <img alt="flos-hero" src={imageUrl} className="flos-hero-bg-fit" />
                </picture>
            )}

            <div className={cx('container', theme === 'transparent' && 'flos-grid')}>
                <div className={cx(theme === 'transparent' ? 'flos-grid--span-6 flos-grid--col-start-1 flos-hero-content--transparent' : 'flos-hero-content')}>
                    <div className={'flos-hero-text'}>
                        {label && <div className={'h5 flos-hero-label'}>{label}</div>}
                        <h1 className={cx('flos-hero-title', !children && !subtitle && 'u-spacing-stack-none')}>{title}</h1>
                        {subtitle && <p className={cx('flos-hero-subtitle large', !children && 'u-spacing-stack-none')}>{subtitle}</p>}
                        {children && children}
                    </div>
                    {actionArea && <div className={'flos-hero-action'}>{actionArea}</div>}
                </div>
                {imageUrl && theme !== 'transparent' && (
                    <div className={'flos-hero-image-container'}>
                        <img alt="" src={imageUrl} className={'flos-hero-image'} />
                    </div>
                )}
                {splashArea && (
                    <div
                        className={cx(
                            theme === 'transparent' ? 'flos-grid--span-6 flos-grid--row-start-mobile-1 flos-hero-splash-container--transparent' : 'flos-hero-splash-container'
                        )}
                    >
                        {splashArea}
                    </div>
                )}
                {theme === 'transparent' && bottomActionArea && <div className={'flos-grid--span-12 flos-hero-bottom-area'}>{bottomActionArea && bottomActionArea}</div>}
            </div>
        </article>
    );
};

export type { HeroBannerProps };
export { HeroBanner };
export default HeroBanner;
