import * as React from 'react';

/**
 * Interface for `RadioControlContext`
 */
export interface IRadioFieldContext {
    value: string | number | boolean | null;
    name: string | undefined;
    isValid?: boolean;
    variant?: 'button-toolbar' | 'button-menu';
    required?: boolean;
    disabled?: boolean;
    errorText?: string | null;
}

/**
 * Low level API to control `RadioInput`.
 *
 * Most of the time you would use `<RadioField />` which use this under the hood.
 */
export const RadioFieldContext = React.createContext<IRadioFieldContext>({
    name: undefined,
    isValid: undefined,
    value: '',
    variant: undefined,
    required: undefined,
    disabled: undefined,
    errorText: undefined,
});
