import * as React from 'react';
import { Tooltip, TooltipProps } from '../tooltip/tooltip';
import { FlosIcon } from '../../icon/flos-icon';
import { getId } from '../../../utils';

export type TooltipIconProps = {
    iconShape?: string;
} & Omit<TooltipProps, 'label'>;
/** Omit because we dont need `label` for TooltipIcon */

/**
 * TooltipIcon is a wrapper of `Tooltip` component supporting tooltip on flos-icon.
 */
export const TooltipIcon = ({ id, iconShape = 'info', avoidPositions = ['left', 'top'], children, ...rest }: TooltipIconProps) => {
    const elementId = id || getId();
    return (
        <Tooltip label={children} avoidPositions={avoidPositions} hasIcon id={`tooltip-${elementId}`} {...rest}>
            <button role="button" onClick={(event) => event.preventDefault()} className="tooltip-icon-button" id={`tooltip-icon-button${elementId}`}>
                <FlosIcon size={'medium'} className={'is-indigo'} shape={iconShape} />
            </button>
        </Tooltip>
    );
};
