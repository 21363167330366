import classnames from 'classnames';
import * as React from 'react';

interface IBoxListProps extends React.HTMLAttributes<HTMLDivElement> {
    hasButton?: boolean;
    hasIcon?: boolean;
}
export const BoxList: React.FC<IBoxListProps> = ({ className, hasButton, hasIcon, ...props }) => (
    <div className={classnames('tds-BoxList', hasButton && 'tds-BoxList--button', hasIcon && 'tds-BoxList--icon', className)} {...props} />
);

export default BoxList;
