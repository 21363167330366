import * as React from 'react';
import cx from 'classnames';
import { FormGroup, IFormGroupProps } from '../../../core/forms/form-group/form-group';
import { includes } from '../../../utils/array';
import { useId } from '../../../utils/hooks';
import { SelectableBox } from './selectable-box';

interface SelectBoxOption {
    label: React.ReactNode;
    value: string;
    disabled?: boolean;
    tooltip?: React.ReactNode;
    description?: React.ReactNode;
    iconShape?: string;
}

interface SelectBoxesBaseFieldProps extends Pick<IFormGroupProps, 'status' | 'maxWidth' | 'compact' | 'required'> {
    id?: string;
    label?: React.ReactNode;
    options: SelectBoxOption[];
    /** specify if the grid should be compact and render a More/Flere button in mobile view when > 2 items. Only applies for non Multiple    */
    compact?: boolean;
    /** The label for the More/Flere button - default: "Flere" */
    moreLabel?: string;
    /** error help text for the field that will only be displayed if status is error */
    errorHelpText?: React.ReactNode;
}

export interface SelectBoxesSingleFieldProps extends SelectBoxesBaseFieldProps {
    value: string;
    onChangeValue: (values: string) => void;
    multiple?: false | undefined;
}

export interface SelectBoxesMultipleFieldProps extends SelectBoxesBaseFieldProps {
    value: string[];
    onChangeValue: (values: string[]) => void;
    multiple: true;
}

export type SelectableBoxFieldProps = SelectBoxesSingleFieldProps | SelectBoxesMultipleFieldProps;

/**
 * `SelectableBoxField` is composed of `FormGroup` and `SelectableBox`.
 *
 * Compose yourself if you need to customize the markup but is not supported by the props.
 */
export const SelectableBoxField = (props: SelectableBoxFieldProps) => {
    return (
        <FormGroup
            style={props.maxWidth ? {} : { maxWidth: 'none' }}
            id={props.id}
            required={props.required}
            status={props.status}
            maxWidth={props.maxWidth}
            compact={props.compact}
        >
            {props.label && <label>{props.label}</label>}
            {props.errorHelpText && props.status === 'error' && <span className="help-block">{props.errorHelpText}</span>}
            {props.multiple ? <SelectBoxesMultipleField {...(props as SelectBoxesMultipleFieldProps)} /> : <SelectBoxesSingleField {...(props as SelectBoxesSingleFieldProps)} />}
        </FormGroup>
    );
};

const SelectBoxesSingleField = ({ value, options, onChangeValue, compact, moreLabel = 'Flere' }: SelectBoxesSingleFieldProps) => {
    const [showPopup, setShowPopup] = React.useState(false);
    const popupMode = compact && options.length > 3;
    return (
        <>
            <div className={cx(compact ? 'Box--selectable-grid-compact' : 'Box--selectable-grid', popupMode && 'Box--selectable-grid-compact-popup')}>
                <>
                    {options.map((option, index) => {
                        return (
                            <SelectableBox
                                key={index}
                                headerText={option.label}
                                active={value === option.value}
                                onSelect={() => {
                                    setShowPopup(false);
                                    onChangeValue(option.value);
                                }}
                                disabled={option.disabled}
                                tooltip={option.tooltip}
                                dimmed={(value ? value !== option.value : false) || showPopup}
                                iconShape={option.iconShape ? option.iconShape : undefined}
                            >
                                {option.description}
                            </SelectableBox>
                        );
                    })}
                    {popupMode && (
                        <SelectableBox
                            headerText={moreLabel}
                            onSelect={() => {
                                setShowPopup(!showPopup);
                            }}
                            isMoreButton={true}
                            active={showPopup}
                            dimmed={true}
                        />
                    )}
                </>
            </div>
            {popupMode && (
                <div className={cx('Box--selectable-grid-popup-menu', showPopup && 'Box--selectable-grid-popup-menu-show')}>
                    {options.map((option, index) => {
                        return (
                            <SelectableBox
                                key={index}
                                headerText={option.label}
                                active={value === option.value}
                                onSelect={() => {
                                    setShowPopup(!showPopup);
                                    onChangeValue(option.value);
                                }}
                                disabled={option.disabled}
                                tooltip={option.tooltip}
                                dimmed={value ? value !== option.value : false}
                                iconShape={option.iconShape ? option.iconShape : undefined}
                            >
                                {option.description}
                            </SelectableBox>
                        );
                    })}
                </div>
            )}
        </>
    );
};

const SelectBoxesMultipleField = ({ value, options, onChangeValue, id }: SelectBoxesMultipleFieldProps) => {
    const uniqueId = useId(id);

    return (
        <div className={cx('Box--selectable-grid')}>
            {options.map((option, index) => {
                const active = includes(value, option.value);
                const onSelect = active ? () => onChangeValue(value.filter((val) => val !== option.value)) : () => onChangeValue(value.concat(option.value));

                return (
                    <SelectableBox
                        key={index}
                        headerText={option.label}
                        active={active}
                        onSelect={onSelect}
                        disabled={option.disabled}
                        tooltip={option.tooltip}
                        id={`${uniqueId}-${option.value}`}
                        iconShape={option.iconShape ? option.iconShape : undefined}
                    >
                        {option.description}
                    </SelectableBox>
                );
            })}
        </div>
    );
};
