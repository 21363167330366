import cx from 'classnames';
import * as React from 'react';

type RowProps = JSX.IntrinsicElements['div'];

/**
 * Bootstrap row
 * @deprecated
 */
export const Row = (props: RowProps) => <div {...props} className={cx('row', props.className)} />;
