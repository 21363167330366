import classnames from 'classnames';
import * as React from 'react';

interface IBoxListStatusProps extends React.HTMLAttributes<HTMLDivElement> {}
/**
 * `BoxListStatus` position itself on the top right of `BoxListItem`.
 */
export const BoxListStatus: React.FC<IBoxListStatusProps> = ({ className, ...props }) => <div className={classnames('tds-BoxList-status', className)} {...props} />;

export default BoxListStatus;
