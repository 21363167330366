import cx from 'classnames';
import * as React from 'react';

type CoverageBoxPillsProps = React.DetailedHTMLProps<React.HTMLAttributes<HTMLSpanElement>, HTMLSpanElement>;

/**
 *  @deprecated
 */

export const CoverageBoxPills: React.FC<CoverageBoxPillsProps> = ({ className, ...props }) => <span className={cx('checked-pills', className)} {...props} />;
