import classnames from 'classnames';
import * as React from 'react';
import { BoxListSubtitle } from './box-list-subtitle';
import { BoxListTitle } from './box-list-title';

type DivProps = React.HTMLAttributes<HTMLDivElement>;

interface IBoxListTitleGroupProps extends Omit<DivProps, 'title'> {
    title?: React.ReactNode;
    subtitle?: React.ReactNode;
}
/**
 * `BoxListTitleGroup` renders `title` and `subtitle` and any children
 * Use you need to customize title and subtitle, compose this with `BoxListTitle` and `BoxListSubtitle`.
 */
export const BoxListTitleGroup: React.FC<IBoxListTitleGroupProps> = ({ className, title, subtitle, children, ...props }) => (
    <div className={classnames('tds-BoxList-titlegroup', className)} {...props}>
        {title && <BoxListTitle>{title}</BoxListTitle>}
        {subtitle && <BoxListSubtitle>{subtitle}</BoxListSubtitle>}
        {children}
    </div>
);

export default BoxListTitleGroup;
